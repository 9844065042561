import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import {
  Tooltip,
  Form,
  Progress,
  CardHeader,
  Nav,
  FormGroup,
  CustomInput,
  CardSubtitle,
  CardTitle,
  CardText,
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  Container,
  Input,
  Button,
  Table,
  Label,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { connect } from 'react-redux';
import profileImg from '../../../assets/images/profile-img.png';
import { Link, useSearchParams } from 'react-router-dom';
import ChatBox from '../chat-box';
import CustomerCard from '../card-contact';
import MapsGoogle from '../MapsGoogle';
import img4 from '../../../assets/images/product/img-4.png';
import img7 from '../../../assets/images/product/img-7.png';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import '../datatables.scss';
import useQuery from '../../../helpers/JIFI/useQuery';
import { effectTypes, select } from 'redux-saga/effects';
import Select from 'react-select';
import SimpleBar from 'simplebar-react';
import classnames from 'classnames';
import CommentHistory from '../../Skote/Dashboard/CommentHistory';
import { useReactToPrint } from 'react-to-print';
import PrintPage from '../monogram/PrintPage';
import PrintPageDeliveries from '../deliveries/delivery-label';
import SkuToName from './skuToName';
import useProductData from './useProductData';
import Autocomplete from 'react-google-autocomplete';

import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';
import moment from 'moment';

import { Country, State } from 'country-state-city';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import FulfillmentModal from './FulfillmentModule';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { usePlacesWidget } from 'react-google-autocomplete';

import AsyncSelect from 'react-select/async';
import OrderWizardComponent from './Wizard/index.js';

import SKUToolTip from '../helpers/SKUToolTip.js';
import useSKULevels from '../helpers/useSKULevels.js';
import useSKUStatus from '../helpers/useSKUStatus.js';
import useUpcomingStock from '../helpers/useUpcomingStock.js';
import ProductVariantSelector from '../helpers/ProductVariantSelector.js';
import useUserStore from '../../../zustand/useUserStore.js';

const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning';
  if (fulfillment === 'awaiting-stock') return 'secondary';
  const conversion = {
    fulfilled: 'success',
    partially_fulfilled: 'error',
  };
  return conversion[fulfillment];
};

const distanceToBadge = (distance) => {
  if (distance < 10000) return 'success';
  return 'danger';
};

const pickToName = (pick) => {
  const conversion = {
    none: 'UNPICKED',
    monogram: 'TO-MONOGRAM',
    fulfillment: 'TO-SHIP',
    EWE: 'EWE',
    QV: 'QV',
    EWESYD: 'EWE Sydney',
    B360: 'B360',
    UNIS: 'UNIS',
    'EWE-MONO': 'EWE-MONO',
    AIRBOX: 'Airbox',
    'AIRBOX-MONO': 'Airbox Monogram',
  };
  return conversion[pick] || pick;
};

const pickToBadge = (pick) => {
  const conversion = {
    none: 'warning',
    monogram: 'info',
    fulfillment: 'success',
    EWE: 'success',
    QV: 'success',
    B360: 'success',
    EWESYD: 'success',
    UNIS: 'success',
    'EWE-MONO': 'info',
    AIRBOX: 'success',
    'AIRBOX-MONO': 'info',
  };
  return conversion[pick] || 'success';
};

const shippingConverter = (pick) => {
  const conversion = {
    pickup: 'Pickup QV',
    pickup_hq: 'Pickup HQ',
    standard: 'Standard',
    'next-day': 'Next Day',
    'same-day': 'Same Day',

    express: 'Express',
    uber: 'Uber',
    Uber: 'Uber',
    overnight: 'Overnight',
  };
  return conversion[pick] || pick;
};

const PreviousOrders = ({ orderID }) => {
  const [refresh, setRefresh] = useState(true);
  const refreshData = () => {
    setRefresh((prev) => !prev);
  };
  const [data, state] = useQuery({
    url: `/orders/${orderID}/customerOrders`,
    refresh,
  });

  if (!data || data.length === 0) return <> </>;

  const filteredOrders = data.filter((val) => orderID !== `${val._id}`);

  return (
    <div className="mt-2 pt-3 border-top border-light">
      <h5 className="mb-2"> {filteredOrders.length} Previous Orders </h5>
      <br />
      {filteredOrders.map((order) => {
        return (
          <div>
            <Link to={`/orders/${order._id}`}>
              {' '}
              <h6>
                {' '}
                {order.name} - ${order.total_price}
              </h6>{' '}
            </Link>
            <div className="mb-3">
              {order.line_items.map((lineItem) => (
                <div>
                  {' '}
                  {lineItem.quantity} x {lineItem.sku}{' '}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

class PackingSlip extends React.Component {
  render() {
    if (!this.props.order) return <></>;
    return (
      <div style={{ width: '100%', height: '100vh', padding: '128px 64px' }}>
        <h1 style={{ marginBottom: 48 }}>
          {' '}
          {this.props.order.name} - PACKING SLIPS{' '}
        </h1>
        <table style={{ fontSize: 24 }}>
          <thead>
            <th style={{ padding: 8 }}>QTY</th>
            <th style={{ padding: 8 }}>SKU</th>
            {/* <th style={{padding: 8}}>NAME</th> */}
          </thead>
          <tbody>
            {this.props.order?.line_items?.map((val) => {
              return (
                <tr>
                  <td style={{ padding: 8 }}>{val.quantity}</td>
                  <td style={{ padding: 8 }}>{val.sku}</td>
                  {/* <td></td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const UnfulfilledLineItems = ({
  shipping_address,
  printDeliveryLabels,
  lineItems,
  orderID,
  order_source,
  refresh,
  shipping_type,
  contains_monogram,
  orderName,
  getVariant,
  distanceFromHQ,
  findSKULevel,
  findUpcomingStock,
  findSKUStatus,
  pick_status,
  postcode,
}) => {
  const [selectedItems, setSelectedItems] = useState(
    lineItems.map((val) => true)
  );
  const [selectedCount, setSelectedCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setSelectedCount(
      selectedItems.reduce(function (n, val) {
        return n + (val === true);
      }, 0)
    );
  }, [selectedItems]);

  useEffect(() => {
    setSelectedItems(
      lineItems.map((val) => val.fulfillment_status !== 'deleted')
    );
  }, [lineItems]);

  const handleFulfillClicked = () => {
    setModalOpen(true);
  };

  return (
    <>
      {lineItems.map((lineItem, key) => {
        const handleSelected = () => {
          setSelectedItems((prev) => {
            let newItems = [...prev];
            newItems[key] = !newItems[key];
            return newItems;
          });
        };
        const isSelected = selectedItems[key] === true;
        return (
          <>
            <Row>
              <Col sm="1">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {lineItem.fulfillment_status !== 'deleted' && (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleSelected}
                      defaultChecked={true}
                      id="defaultCheck1"
                    />
                  )}
                </div>
              </Col>
              <Col sm="2">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={getVariant(lineItem.sku)?.image}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col sm="5" className="p-3">
                <h6>{getVariant(lineItem.sku)?.name}</h6>
                <div>
                  <Badge
                    className={
                      'font-size-12 badge-soft-' +
                      fulfillmentToBadge(lineItem.fulfillment_status)
                    }
                    color={fulfillmentToBadge(lineItem.fulfillment_status)}
                    pill
                  >
                    {lineItem.fulfillment_status
                      ? lineItem.fulfillment_status
                          .replace('_', ' ')
                          .toUpperCase()
                      : 'UN-FULFILLED'}
                  </Badge>
                </div>
                <div>
                  SKU:{' '}
                  <SKUToolTip
                    sku={lineItem.sku}
                    findSKULevel={findSKULevel}
                    findUpcomingStock={findUpcomingStock}
                    findSKUStatus={findSKUStatus}
                  />
                </div>
              </Col>
              <Col sm="2" className="p-3">
                {lineItem.monogram && !lineItem.pet && (
                  <a
                    href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                      lineItem.monogram_text
                    )}-${lineItem.monogram_color}-${
                      lineItem.monogram_shadow || ''
                    }-undefined${
                      lineItem.sku.startsWith('IPC')
                        ? `-IPC-${lineItem.monogram_position}`
                        : ''
                    }${
                      lineItem.sku.startsWith('BOT') &&
                      lineItem.monogram_position === 'Top'
                        ? '-Lid'
                        : ''
                    }.pdf?avoidTheCaches=1`}`}
                    target="_blank"
                  >
                    <div style={{ opactity: 0.8, fontSize: 10 }}>
                      <div>TEXT: {lineItem.monogram_text}</div>
                      <div>FONT: {lineItem.monogram_font}</div>
                      <div>COLOUR: {lineItem.monogram_color}</div>
                      {(lineItem.monogram_font === 'block' ||
                        lineItem.monogram_font === 'college' ||
                        lineItem.monogram_font === 'heritage' ||
                        lineItem.monogram_font === 'decorative') && (
                        <div>SHADOW: {lineItem.monogram_shadow}</div>
                      )}
                      <div>POSITION: {lineItem.monogram_position}</div>
                    </div>
                  </a>
                )}
                {lineItem.pet &&
                  lineItem.monogram_airtable_line &&
                  lineItem.monogram_airtable_line.map((val, key) => (
                    <a
                      href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                      target="_blank"
                    >
                      PET {key}
                    </a>
                  ))}

                {lineItem.message && (
                  <div style={{ opactity: 0.8, fontSize: 10 }}>
                    <div>message: {lineItem.message}</div>
                  </div>
                )}
              </Col>
              <Col sm="2" className="p-3">
                {lineItem.quantity}x ${lineItem.price}
              </Col>
              {/* <Col sm="2" className="p-3">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="card-drop" tag="i">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => { }} href="#"></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col> */}
            </Row>
          </>
        );
      })}
      <Row>
        {selectedCount > 0 && (
          <Button onClick={handleFulfillClicked} type="submit" color="success">
            {' '}
            Fulfill Selected
          </Button>
        )}
      </Row>
      <Modal
        size="lg"
        isOpen={modalOpen}
        toggle={() => setModalOpen((prev) => !prev)}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Fulfillment method</h5>
          <button
            type="button"
            onClick={() => setModalOpen(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <FulfillmentModal
          shipping_address={shipping_address}
          postcode={postcode}
          pick_status={pick_status}
          printDeliveryLabels={printDeliveryLabels}
          distanceFromHQ={distanceFromHQ}
          orderName={orderName}
          contains_monogram={contains_monogram}
          refresh={refresh}
          lineItems={lineItems}
          orderID={orderID}
          shipping_type={shipping_type}
          order_source={order_source}
          selectedItems={selectedItems}
          setModalOpen={setModalOpen}
        />
      </Modal>
    </>
  );
};

const SplitLineItems = ({
  lineItems,
  orderID,
  order_source,
  refresh,
  shipping_type,
  contains_monogram,
  orderName,
  getVariant,
  distanceFromHQ,
  findSKULevel,
  findSKUStatus,
  findUpcomingStock,
}) => {
  const [selectedItems, setSelectedItems] = useState(
    lineItems.map((val) => true)
  );
  const [selectedCount, setSelectedCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedCount(
      selectedItems.reduce(function (n, val) {
        return n + (val === true);
      }, 0)
    );
  }, [selectedItems]);

  useEffect(() => {
    setSelectedItems(
      lineItems.map((val) => val.fulfillment_status !== 'deleted')
    );
  }, [lineItems]);

  const handleFulfillClicked = () => {
    setModalOpen(true);
  };

  const [manualName, setManualName] = useState(orderName);
  useEffect(() => {
    setManualName(orderName);
  }, [orderName]);
  const handleManualNameChange = (e) => {
    setManualName(e.target.value);
  };

  const handleCreateSplit = () => {
    setLoading(true);
    const sendLineItems = selectedItems
      .map((val, key) => (val ? lineItems[key] : null))
      .filter((el) => el != null);

    superagent
      .post(`${backendUrl}/orders/splitOrder`)
      .send({
        line_items: sendLineItems,
        orderId: orderID,
        updatedName: manualName,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          setLoading(false);
          setModalOpen(false);
          refresh();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {lineItems.map((lineItem, key) => {
        const handleSelected = () => {
          setSelectedItems((prev) => {
            let newItems = [...prev];
            newItems[key] = !newItems[key];
            return newItems;
          });
        };
        const isSelected = selectedItems[key] === true;
        return (
          <>
            <Row>
              <Col sm="1">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {lineItem.fulfillment_status !== 'deleted' && (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleSelected}
                      checked={isSelected}
                      id="defaultCheck1"
                    />
                  )}
                </div>
              </Col>
              <Col sm="2">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={getVariant(lineItem.sku)?.image}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col sm="5" className="p-3">
                <h6>{getVariant(lineItem.sku)?.name}</h6>
                <div>
                  <Badge
                    className={
                      'font-size-12 badge-soft-' +
                      fulfillmentToBadge(lineItem.fulfillment_status)
                    }
                    color={fulfillmentToBadge(lineItem.fulfillment_status)}
                    pill
                  >
                    {lineItem.fulfillment_status
                      ? lineItem.fulfillment_status
                          .replace('_', ' ')
                          .toUpperCase()
                      : 'UN-FULFILLED'}
                  </Badge>
                </div>
                <div>
                  SKU:{' '}
                  <SKUToolTip
                    sku={lineItem.sku}
                    findSKULevel={findSKULevel}
                    findUpcomingStock={findUpcomingStock}
                    findSKUStatus={findSKUStatus}
                  />
                </div>
              </Col>
              <Col sm="2" className="p-3">
                {lineItem.monogram && !lineItem.pet && (
                  <a
                    href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                      lineItem.monogram_text
                    )}-${lineItem.monogram_color}-${
                      lineItem.monogram_shadow || ''
                    }-undefined${
                      lineItem.sku.startsWith('IPC')
                        ? `-IPC-${lineItem.monogram_position}`
                        : ''
                    }${
                      lineItem.sku.startsWith('BOT') &&
                      lineItem.monogram_position === 'Top'
                        ? '-Lid'
                        : ''
                    }.pdf?avoidTheCaches=1`}`}
                    target="_blank"
                  >
                    <div style={{ opactity: 0.8, fontSize: 10 }}>
                      <div>TEXT: {lineItem.monogram_text}</div>
                      <div>FONT: {lineItem.monogram_font}</div>
                      <div>COLOUR: {lineItem.monogram_color}</div>
                      {(lineItem.monogram_font === 'block' ||
                        lineItem.monogram_font === 'college' ||
                        lineItem.monogram_font === 'heritage' ||
                        lineItem.monogram_font === 'decorative') && (
                        <div>SHADOW: {lineItem.monogram_shadow}</div>
                      )}
                      <div>POSITION: {lineItem.monogram_position}</div>
                    </div>
                  </a>
                )}
                {lineItem.pet &&
                  lineItem.monogram_airtable_line &&
                  lineItem.monogram_airtable_line.map((val, key) => (
                    <a
                      href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                      target="_blank"
                    >
                      PET {key}
                    </a>
                  ))}

                {lineItem.message && (
                  <div style={{ opactity: 0.8, fontSize: 10 }}>
                    <div>message: {lineItem.message}</div>
                  </div>
                )}
              </Col>
              <Col sm="2" className="p-3">
                QUANTITY: {lineItem.quantity}
              </Col>
            </Row>
          </>
        );
      })}
      <Row>
        {selectedCount > 0 && (
          <Button onClick={handleFulfillClicked} type="submit" color="success">
            {' '}
            Split Order
          </Button>
        )}
      </Row>
      <Modal
        size="lg"
        isOpen={modalOpen}
        toggle={() => setModalOpen((prev) => !prev)}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Split Order</h5>
          <button
            type="button"
            onClick={() => setModalOpen(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {/* <Input onChange={handleManualNameChange} value={manualName} placeholder="Tracking Url" /> */}
          <div className="my-3">
            {lineItems
              .filter((val, key) => selectedItems[key] === true)
              .map((val, key) => (
                <div className="my-1">
                  {' '}
                  {val.sku} x {val.quantity}{' '}
                </div>
              ))}
          </div>
        </ModalBody>
        <ModalFooter>
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <Button color="danger" onClick={handleCreateSplit}>
              {' '}
              Create Split{' '}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

const MonogramLineItems = ({
  order,
  refresh,
  getVariant,
  airtableData,
  findSKULevel,
  findUpcomingStock,
  findSKUStatus,
}) => {
  const handleUpdateItem = (id, machine) => {
    superagent
      .post(`${backendUrl}/orders/updateMonogramLineItem`)
      .send({ id, order_id: order._id, monogrammed_by: machine })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    order?.line_items &&
    order.line_items
      .filter(
        (val) =>
          val.monogram === true &&
          val.fulfillment_status !== 'unfulfilled' &&
          val.fulfillment_status !== 'fulfilled'
      )
      .map((lineItem) => {
        return (
          <Row>
            <Col sm="2">
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={getVariant(lineItem.sku)?.image}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
            <Col sm="5" className="p-3">
              <h6>{getVariant(lineItem.sku)?.name} </h6>
              <h6 className="text-muted">
                {' '}
                {lineItem.monogrammed_by &&
                  `Monogrammed by ${lineItem.monogrammed_by
                    .replace('iron-man', 'Iron Man')
                    .replace('green-machine', 'Green Machine')
                    .replace('pitt-st', 'Pitt St')}`}
              </h6>
              <div>
                <Badge
                  className={
                    'font-size-12 badge-soft-' +
                    fulfillmentToBadge(lineItem.fulfillment_status)
                  }
                  color={fulfillmentToBadge(lineItem.fulfillment_status)}
                  pill
                >
                  {lineItem.fulfillment_status
                    ? lineItem.fulfillment_status
                        .replace('_', ' ')
                        .toUpperCase()
                    : 'UN-FULFILLED'}
                </Badge>
              </div>
              <div>
                SKU:{' '}
                <SKUToolTip
                  sku={lineItem.sku}
                  findSKULevel={findSKULevel}
                  findUpcomingStock={findUpcomingStock}
                  findSKUStatus={findSKUStatus}
                />
              </div>
            </Col>
            <Col sm="2" className="p-3">
              {lineItem.monogram && !lineItem.pet && (
                <a
                  href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                    lineItem.monogram_text
                  )}-${lineItem.monogram_color}-${
                    lineItem.monogram_shadow || ''
                  }-undefined${
                    lineItem.sku.startsWith('IPC')
                      ? `-IPC-${lineItem.monogram_position}`
                      : ''
                  }${
                    lineItem.sku.startsWith('BOT') &&
                    lineItem.monogram_position === 'Top'
                      ? '-Lid'
                      : ''
                  }.pdf?avoidTheCaches=1`}`}
                  target="_blank"
                >
                  <div style={{ opactity: 0.8, fontSize: 10 }}>
                    <div>TEXT: {lineItem.monogram_text}</div>
                    <div>FONT: {lineItem.monogram_font}</div>
                    <div>COLOUR: {lineItem.monogram_color}</div>
                    {(lineItem.monogram_font === 'block' ||
                      lineItem.monogram_font === 'college' ||
                      lineItem.monogram_font === 'heritage' ||
                      lineItem.monogram_font === 'decorative') && (
                      <div>SHADOW: {lineItem.monogram_shadow}</div>
                    )}
                    <div>POSITION: {lineItem.monogram_position}</div>
                  </div>
                </a>
              )}
              {lineItem.pet &&
                lineItem.monogram_airtable_line &&
                airtableData &&
                lineItem.monogram_pets &&
                lineItem.monogram_airtable_line.map((val, key) => {
                  const currentAirtableFind = airtableData.find(
                    (airtablerecord) => airtablerecord.id === val
                  );
                  if (!currentAirtableFind) return <></>;
                  const currentAirtable = currentAirtableFind['fields'];
                  return (
                    <div>
                      <a
                        href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                        target="_blank"
                      >
                        <div>POSITION: {lineItem.monogram_position}</div>
                        <div>
                          {JSON.parse(atob(lineItem.monogram_pets))[key].name}{' '}
                          {currentAirtable['Status']}
                        </div>
                        <div>
                          <img
                            src={
                              currentAirtable['Completed illustration'] ===
                              undefined
                                ? currentAirtable['Provided Image'][0][
                                    'thumbnails'
                                  ]['small']['url']
                                : currentAirtable['Completed illustration'][0][
                                    'thumbnails'
                                  ]['small']['url']
                            }
                          />
                        </div>
                      </a>
                    </div>
                  );
                })}
            </Col>
            <Col sm="2" className="p-3">
              {lineItem.quantity}x ${lineItem.price}
            </Col>
            <Col sm="1" className="p-3">
              <UncontrolledDropdown>
                <DropdownToggle href="#" className="card-drop" tag="i">
                  <i className="mdi mdi-dots-horizontal font-size-18"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  {lineItem.fulfillment_status === 'to-be-monogrammed' && (
                    <>
                      <DropdownItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'green-machine');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( Green Machine ){' '}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'iron-man');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( Iron-Man){' '}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'julie');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for embroidery ( Julie ){' '}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'white-machine');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( White Machine ){' '}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'grey-machine');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( Grey Machine ){' '}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'pitt-st');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( Pitt St ){' '}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'emoss');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( Embossing ){' '}
                      </DropdownItem>
                    </>
                  )}
                  {lineItem.fulfillment_status === 'in-for-monogram' && (
                    <DropdownItem
                      onClick={() => {
                        handleUpdateItem(lineItem._id);
                      }}
                      href="#"
                    >
                      {' '}
                      Mark ready to ship{' '}
                    </DropdownItem>
                  )}

                  {lineItem.pet === true && (
                    <DropdownItem
                      onClick={() => {
                        alert('NEED TO IMPLEMENT COPY EMAIL');
                      }}
                      href="#"
                    >
                      {' '}
                      Contact customer{' '}
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        );
      })
  );
};

const OrderPage = ({ match: { params }, google }) => {
  const { orderID } = params;
  const [refresh, setRefresh] = useState(true);
  const [modalEdit, setModalEdit] = useState();
  const [modalFlag, setModalFlag] = useState();
  const [modalAssignedUser, setModalAssignedUser] = useState();
  const [returnModal, setModalReturn] = useState();
  const [returnModalOld, setModalReturnOld] = useState();
  const [shippingModalEdit, setShippingModalEdit] = useState();
  const [addModalEdit, setAddModalEdit] = useState();
  const [editLineItem, setEditLineItem] = useState();
  const [flagLineItem, setFlagLineItem] = useState();
  const [cancelOpen, setCancelOpen] = useState();
  
  const userList = useUserStore((state) => state.userList);

  const getVariant = useProductData();

  const refreshData = () => {
    setRefresh((prev) => !prev);
  };


  const [data, state] = useQuery({
    url: `/orders/${orderID}`,
    refresh,
  });

  

  const [airtableData, airtableDataState] = useQuery({
    url: `/orders/fetchAllPetAirtableFromOrder/${orderID}`,
    refresh,
  });

  const findSKULevel = useSKULevels();
  const findUpcomingStock = useUpcomingStock();
  const findSKUStatus = useSKUStatus();

  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const packingSlipRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => packingSlipRef.current,
  });

  const packingLabelsRef = useRef();
  const printLabels = useReactToPrint({
    content: () => packingLabelsRef.current,
  });
  const handlePrintLabel = () => {
    printLabels();
  };

  const nonpackingLabelsRef = useRef();
  const nonprintLabels = useReactToPrint({
    content: () => nonpackingLabelsRef.current,
  });
  const nonhandlePrintLabel = () => {
    nonprintLabels();
  };

  const deliveryLabels = useRef();
  const printDeliveryLabels = useReactToPrint({
    content: () => deliveryLabels.current,
  });

  const [customActiveTab, setCustomActiveTab] = useState('1');

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const handleEditLineItem = (id) => {
    setEditLineItem(id);
    togglemodalEdit();
  };

  const handleFlagLineItem = (id) => {
    setFlagLineItem(id);
    togglemodalFlag();
  };

  const handleRemoveAwaitingStock = (id) => {
    superagent
      .post(`${backendUrl}/orders/removeAwaitingStock/${id}`)
      .send({
        orderID: orderID,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAssignedUser = (id) => {
    superagent
      .post(`${backendUrl}/orders/assignedUser`)
      .send({
        orderID,
        userID: id,
        url: window.location.href
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
          handleToggleAssignedUser()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteLineItem = (id) => {
    superagent
      .post(`${backendUrl}/orders/cancelLineItem/${id}`)
      .send({
        orderID: orderID,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const order = data ? data : {};

  useEffect(() => {
    if (data?.name) {
      document.title = data.name + ' | JIFI';
    }
  }, [data?.name]);

  const [allUniquePets, setAllUniquePets] = useState({});
  const addUniquePet = (pet, airtableID) => {
    setAllUniquePets((prev) => {
      let all = { ...prev };
      all[airtableID] = pet;
      return all;
    });
  };
  useEffect(() => {
    if (order && airtableData && order?.line_items) {
      order.line_items.map((lineItem) => {
        if (lineItem.pet === true) {
          let petData = JSON.parse(atob(lineItem.monogram_pets));
          petData.map((pet, petKey) => {
            let airtableID = lineItem.monogram_airtable_line[petKey];
            const currentAirtableFind = airtableData.find(
              (airtablerecord) => airtablerecord.id === airtableID
            );

            if (currentAirtableFind !== undefined) {
              const currentAirtable = currentAirtableFind['fields'];

              allUniquePets[airtableID] = {
                ...pet,
                ...currentAirtable,
              };
            }
          });
        }
      });
    }
  }, [airtableData, order]);

  if (!data) {
    return <div></div>;
  }

  const labelData = order.line_items
    .filter((val) => val.monogram && val.fulfillment_status !== 'deleted')
    .map((data) => ({
      monogramId: data.monogramId,
      location: data.monogram_position,
      letters: data.monogram_text,
      font: data.monogram_font,
      mainCol: data.monogram_color,
      backCol: data.monogram_shadow,
      created_at: order.orderCreated,
      shopifyId: order.name,
      country: order.shipping_address.country,
      lineItems: order.line_items
        .filter(
          (val) => val._id !== data._id && val.fulfillment_status !== 'deleted'
        )
        .map((val) => val.sku),
      sku: data.sku,
      quantity: data.quantity,
      embroided: data.embroided,

      pet: data.pet,
      monogram_pets: data.monogram_pets && JSON.parse(atob(data.monogram_pets)),
      monogram_airtable_line: data.monogram_airtable_line,
      completedThumbnails: order.completedThumbnails,
    }));

  const nonLabelData = order.line_items
    .filter((val) => !val.monogram && val.fulfillment_status !== 'deleted')
    .map((data) => ({
      monogramId: data.monogramId,
      location: data.monogram_position,
      letters: data.monogram_text,
      font: data.monogram_font,
      mainCol: data.monogram_color,
      backCol: data.monogram_shadow,
      created_at: order.orderCreated,
      shopifyId: order.name,
      country: order.shipping_address.country,
      lineItems: order.line_items
        .filter(
          (val) => val._id !== data._id && val.fulfillment_status !== 'deleted'
        )
        .map((val) => val.sku),
      sku: data.sku,
      quantity: data.quantity,
    }));

  const handleCancel = (e) => {
    e.preventDefault();
    toggleCancelOpen();
    superagent
      .get(`${backendUrl}/orders/${orderID}/cancel`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFlag = (e) => {
    e.preventDefault();
    if (order.flagged === true) {
      superagent
        .get(`${backendUrl}/orders/${orderID}/getFlagged`)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          console.log(response.body);
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            console.log(response.body);
            refreshData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      handleFlagLineItem(orderID);
    }
  };

  const handleRefreshMonogramFiles = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/${orderID}/createAllMonograms`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleContacted = (e) => {
    e.preventDefault();
    superagent
      .get(`${backendUrl}/orders/${orderID}/contacted`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSendLaunchLabel = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/resendLaunchLabels/${orderID}`)
      .send({})
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleManuallyFulfill = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/manualFulfillNoPick`)
      .send({
        orderId: orderID,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRefreshFulfillment = (e) => {
    e.preventDefault();
    superagent
      .get(`${backendUrl}/orders/${orderID}/refreshFulfillmentStatus`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleToggleAssignedUser = () => {
    setModalAssignedUser(!modalAssignedUser);
  };

  const handleUnpick = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/${orderID}/unpick`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelOrderFulfillment = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/${orderID}/cancelFulfillments`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const togglemodalEdit = () => {
    setModalEdit((prev) => !prev);
    // printLabels()
  };

  const togglemodalReturn = () => {
    setModalReturn((prev) => !prev);
    // printLabels()
  };

  const togglemodalReturnOld = () => {
    setModalReturnOld((prev) => !prev);
    // printLabels()
  };

  const toggleAddModalEdit = () => {
    setAddModalEdit((prev) => !prev);
    // printLabels()
  };

  const toggleShippingNodalEdit = () => {
    setShippingModalEdit((prev) => !prev);
    // printLabels()
  };

  const toggleCancelOpen = () => {
    setCancelOpen((prev) => !prev);
    // printLabels()
  };

  const togglemodalFlag = () => {
    setModalFlag((prev) => !prev);
    // printLabels()
  };

  const handleEditShipping = (e) => {
    e.preventDefault();
    toggleShippingNodalEdit();
  };

  const toggleWizardComponent = () => {
    setPopupOpen((prev) => !prev);
  };

  const orderSourceToShopify = {
    shopify_au: 'AU',
    qv_store: 'AU',
    galeries_store: 'AU',
    bondi: 'AU',
    '80_collins': 'AU',
    hq_store: 'AU',
    pitt_st: 'AU',
    pacfair: 'AU',
    james_st: 'AU',
    parramatta: 'AU',

    chadstone: 'AU',
    greene: 'US',

    'shopify_nz': 'NZ',
    'shopify_us': 'US',
    'shopify_uk': 'UK',
    'shopify_ca': 'CA',
  }
  const shopifyLocale = orderSourceToShopify[order.order_source]
  const shopifyToUrl = {
    'AU': 'https://july-travel.myshopify.com/',
    'US': 'https://july-us.myshopify.com/',
    'NZ': 'https://july-nz-store.myshopify.com/',
    'UK': 'https://july-uk.myshopify.com/',
    'CA': 'https://july-ca.myshopify.com/',
  }

  let shopifyUrl;
  if (shopifyLocale) {
    shopifyUrl = shopifyToUrl[shopifyLocale];
  }

  const user = JSON.parse(localStorage.getItem('authUser'));

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col s="12" md="8">
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <h2 className="mb-4">
                      {order.name}
                      <Button
                        style={{ marginLeft: 16 }}
                        color="primary"
                        className="btn-width-20"
                        onClick={handleOpenPopup}
                      >
                        Order Wizard
                      </Button>
                      <UncontrolledDropdown
                        style={{ display: 'inline-block', padding: '0 16px' }}
                      >
                        <DropdownToggle href="#" className="card-drop" tag="i">
                          <i className="mdi mdi-dots-horizontal font-size-18"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {(order.fulfillment_status === 'unfulfilled' ||
                            order.fulfillment_status === 'awaiting-stock') && (
                            <DropdownItem onClick={toggleCancelOpen} href="#">
                              {' '}
                              Cancel{' '}
                            </DropdownItem>
                          )}
                          {user.email === 'lachi@july.com' && (
                            <DropdownItem
                              onClick={handleManuallyFulfill}
                              href="#"
                            >
                              {' '}
                              Manually Fulfill Without Picking{' '}
                            </DropdownItem>
                          )}
                          {order.pick_status === 'LAUNCH-MONO' && (
                            <DropdownItem
                              onClick={handleSendLaunchLabel}
                              href="#"
                            >
                              {' '}
                              Resend Launch Labels{' '}
                            </DropdownItem>
                          )}
                          <DropdownItem onClick={(e) => handleFlag(e)} href="#">
                            {' '}
                            {order.flagged ? 'Unflag' : 'Flag'}{' '}
                          </DropdownItem>
                          {order.contacted === false && (
                            <DropdownItem onClick={handleContacted} href="#">
                              {' '}
                              Contacted customer{' '}
                            </DropdownItem>
                          )}
                          <DropdownItem
                            onClick={handleRefreshFulfillment}
                            href="#"
                          >
                            {' '}
                            Refresh Fulfillment Status{' '}
                          </DropdownItem>

                          <DropdownItem onClick={togglemodalReturnOld} href="#">
                            {' '}
                            Create return in spreadsheet
                          </DropdownItem>
                          <DropdownItem onClick={togglemodalReturn} href="#">
                            {' '}
                            Create repair in JIFI
                          </DropdownItem>
                          {order.contains_monogram === true && (
                            <DropdownItem
                              onClick={handleRefreshMonogramFiles}
                              href="#"
                            >
                              {' '}
                              Refresh all Monograms{' '}
                            </DropdownItem>
                          )}
                          {order.fulfillment_status === 'fulfilled' && (
                            <DropdownItem
                              onClick={cancelOrderFulfillment}
                              href="#"
                            >
                              {' '}
                              Unfulfill{' '}
                            </DropdownItem>
                          )}
                          {order.pick_status !== 'none' && (
                            <DropdownItem onClick={handleUnpick} href="#">
                              {' '}
                              Unpick{' '}
                            </DropdownItem>
                          )}
                          {
                            <DropdownItem
                              onClick={handleToggleAssignedUser}
                              href="#"
                            >
                              Assigned User
                            </DropdownItem>
                          }
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </h2>
                    <h6 className="mb-4">
                      {new Date(order.orderCreated).toString()}
                    </h6>
                    <div> {order?.shipping_address?.name} </div>
                    {order?.customer?.email && (
                      <div>
                        {' '}
                        <span style={{ opacity: 0.5 }}>EMAIL:</span>{' '}
                        {order?.customer?.email}{' '}
                      </div>
                    )}
                    <br />
                    {order.order_source === 'tmall' && (
                      <div style={{ fontWeight: 600 }}>
                        {' '}
                        SHIP BEFORE:{' '}
                        {moment(order.orderCreated)
                          .add(4, 'days')
                          .format('DD-MM-YYYY')}{' '}
                      </div>
                    )}
                    <br />
                    <Badge
                      className={
                        'mr-2 px-4 py-2 font-size-12 badge-soft-' +
                        order.badgeclass
                      }
                      color={order.badgeClass}
                      pill
                    >
                      {order?.financial_status.toUpperCase()}
                    </Badge>
                    <Badge
                      className={
                        'mr-2 px-4 py-2 font-size-12 badge-soft-' +
                        order.badgeclass
                      }
                      color={order.badgeClass}
                      pill
                    >
                      {shippingConverter(order?.shipping_type)?.toUpperCase()}
                    </Badge>
                    {order.flagged && (
                      <Badge
                        className={
                          'mr-2 px-4 py-2 font-size-12 badge-soft-danger'
                        }
                        pill
                      >
                        FLAGGED
                      </Badge>
                    )}
                    {order.partially_fulfilled && (
                      <Badge
                        className={
                          'mr-2 px-4 py-2 font-size-12 badge-soft-warning'
                        }
                        pill
                      >
                        PARTIAL FULFILLMENT
                      </Badge>
                    )}
                    <br />
                    <div className="py-4 my-4 border-top border-bottom">
                      <table>
                        <tr className="py-5">
                          <td className="text-right">
                            {' '}
                            <b>ORDER STATUS:</b>{' '}
                          </td>
                          <td className="pl-3">
                            {order.ready_to_ship && !order.flagged ? (
                              <Badge
                                className={
                                  'ml-2 px-4 py-2 font-size-12 badge-soft-success'
                                }
                                pill
                              >
                                READY
                              </Badge>
                            ) : (
                              <Badge
                                className={
                                  'ml-2 px-4 py-2 font-size-12 badge-soft-danger'
                                }
                                pill
                              >
                                NOT READY
                              </Badge>
                            )}
                          </td>
                        </tr>
                        <br />
                        <tr className="py-5">
                          <td className="text-right">
                            {' '}
                            <b>FREIGHT PROTECTION:</b>{' '}
                          </td>
                          <td className="pl-3">
                            {order.freight_protection ? (
                              <Badge
                                className={
                                  'ml-2 px-4 py-2 font-size-12 badge-soft-success'
                                }
                                pill
                              >
                                PROTECTED
                              </Badge>
                            ) : (
                              <Badge
                                className={
                                  'ml-2 px-4 py-2 font-size-12 badge-soft-danger'
                                }
                                pill
                              >
                                NOT PROTECTED
                              </Badge>
                            )}
                          </td>
                        </tr>
                        <br />
                        <tr className="py-5">
                          <td className="text-right">
                            {' '}
                            <b>PICK STATUS:</b>{' '}
                          </td>
                          <td className="pl-3">
                            <Badge
                              className={
                                'ml-2 px-4 py-2 font-size-12 badge-soft-' +
                                pickToBadge(order.pick_status)
                              }
                              color={pickToBadge(order.pick_status)}
                              pill
                            >
                              {pickToName(order.pick_status)}
                            </Badge>
                          </td>
                        </tr>
                        <br />
                        <tr className="py-5">
                          <td className="text-right">
                            {' '}
                            <b>FULFILLMENT STATUS:</b>{' '}
                          </td>
                          <td className="pl-3">
                            <Badge
                              className={
                                'ml-2  px-4 py-2 font-size-12 badge-soft-' +
                                fulfillmentToBadge(order.fulfillment_status)
                              }
                              color={fulfillmentToBadge(
                                order.fulfillment_status
                              )}
                              pill
                            >
                              {order.fulfillment_status
                                ? order.fulfillment_status
                                    .replace('_', ' ')
                                    .toUpperCase()
                                : 'UN-FULFILLED'}
                            </Badge>
                          </td>
                        </tr>
                        <br />
                      </table>
                    </div>

                    <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light btn btn-primary"
                      style={{ marginRight: 8, marginTop: 8 }}
                      onClick={handlePrint}
                    >
                      Packing Slip
                    </Button>
                    {order.contains_monogram && (
                      <Button
                        color="primary"
                        onClick={handlePrintLabel}
                        className="btn btn-primary waves-effect waves-light btn btn-primary"
                        style={{ marginRight: 8, marginTop: 8 }}
                      >
                        Print monogram labels
                      </Button>
                    )}
                    <Button
                      onClick={nonhandlePrintLabel}
                      color="primary"
                      className="btn btn-primary waves-effect waves-light btn btn-primary"
                      style={{ marginRight: 8, marginTop: 8 }}
                    >
                      Print non-monogram labels
                    </Button>
                    <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light btn btn-primary"
                      style={{ marginRight: 8, marginTop: 8 }}
                      onClick={printDeliveryLabels}
                    >
                      {' '}
                      Delivery Label{' '}
                    </Button>

                    {shopifyUrl && (
                      <a
                        target="_blank"
                        href={`${shopifyUrl}admin/orders/${order.shopify_id}?orderListBeta=true`}
                      >
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light btn btn-primary"
                          style={{ marginRight: 8, marginTop: 8 }}
                        >
                          View in Shopify
                        </Button>
                      </a>
                    )}
                    {order.fulfillment_tracking_url && (
                      <a target="_blank" href={order.fulfillment_tracking_url}>
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light btn btn-primary"
                          style={{ marginRight: 8, marginTop: 8 }}
                        >
                          TRACKING URL
                        </Button>
                      </a>
                    )}
                    {order.fulfillment_status === 'fulfilled' &&
                      order.order_source === 'tmall' && (
                        <a
                          target="_blank"
                          href={order.line_items[0].shipping_label_url}
                        >
                          <Button
                            color="primary"
                            className="btn btn-primary waves-effect waves-light btn btn-primary"
                            style={{ marginRight: 8, marginTop: 8 }}
                          >
                            TMALL LABEL
                          </Button>
                        </a>
                      )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CardTitle>Line Items</CardTitle>

                    <Nav tabs className="">
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: customActiveTab === '1',
                          })}
                          onClick={() => {
                            toggleCustom('1');
                          }}
                        >
                          All
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: customActiveTab === '2',
                          })}
                          onClick={() => {
                            toggleCustom('2');
                          }}
                        >
                          Unfulfilled
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: customActiveTab === '3',
                          })}
                          onClick={() => {
                            toggleCustom('3');
                          }}
                        >
                          Monogramming
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: customActiveTab === '4',
                          })}
                          onClick={() => {
                            toggleCustom('4');
                          }}
                        >
                          Fulfilled
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            active: customActiveTab === '5',
                          })}
                          onClick={() => {
                            toggleCustom('5');
                          }}
                        >
                          Split
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={customActiveTab}>
                      <TabPane tabId="1" className="p-3">
                        <Row>
                          <Col sm="12">
                            {order?.line_items &&
                              order.line_items
                                .filter((val) => val.sku !== undefined)
                                .map((lineItem) => {
                                  return (
                                    <Row>
                                      <Col sm="2">
                                        <div
                                          style={{
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <img
                                            src={
                                              getVariant(lineItem.sku)?.image
                                            }
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4" className="p-3">
                                        <h6>
                                          {getVariant(lineItem.sku)?.name}
                                        </h6>
                                        <h6 className="text-muted">
                                          {' '}
                                          {lineItem.monogrammed_by &&
                                            `Monogrammed by ${lineItem.monogrammed_by
                                              .replace('iron-man', 'Iron Man')
                                              .replace(
                                                'green-machine',
                                                'Green Machine'
                                              )
                                              .replace('pitt-st', 'Pitt St')}`}
                                        </h6>
                                        <div>
                                          <Badge
                                            className={
                                              'font-size-12 badge-soft-' +
                                              fulfillmentToBadge(
                                                lineItem.fulfillment_status
                                              )
                                            }
                                            color={fulfillmentToBadge(
                                              lineItem.fulfillment_status
                                            )}
                                            pill
                                          >
                                            {lineItem.fulfillment_status
                                              ? lineItem.fulfillment_status
                                                  .replace('_', ' ')
                                                  .toUpperCase()
                                              : 'UN-FULFILLED'}
                                          </Badge>
                                        </div>
                                        <div>
                                          SKU:{' '}
                                          <SKUToolTip
                                            sku={lineItem.sku}
                                            findSKULevel={findSKULevel}
                                            findUpcomingStock={
                                              findUpcomingStock
                                            }
                                            findSKUStatus={findSKUStatus}
                                          />
                                        </div>

                                        <div>
                                          Allocated Warehouse:{' '}
                                          {lineItem.allocatedWarehouse}
                                        </div>
                                      </Col>
                                      <Col sm="2" className="p-3">
                                        {lineItem.monogram && !lineItem.pet && (
                                          <a
                                            href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                                              lineItem.monogram_text
                                            )}-${lineItem.monogram_color}-${
                                              lineItem.monogram_shadow || ''
                                            }-undefined${
                                              lineItem.sku.startsWith('IPC')
                                                ? `-IPC-${lineItem.monogram_position}`
                                                : ''
                                            }${
                                              lineItem.sku.startsWith('BOT') &&
                                              lineItem.monogram_position ===
                                                'Top'
                                                ? '-Lid'
                                                : ''
                                            }.pdf?avoidTheCaches=1`}`}
                                            target="_blank"
                                          >
                                            <div
                                              style={{
                                                opactity: 0.8,
                                                fontSize: 10,
                                              }}
                                            >
                                              <div>
                                                TEXT: {lineItem.monogram_text}
                                              </div>
                                              <div>
                                                FONT: {lineItem.monogram_font}
                                              </div>
                                              <div>
                                                COLOUR:{' '}
                                                {lineItem.monogram_color}
                                              </div>
                                              {(lineItem.monogram_font ===
                                                'block' ||
                                                lineItem.monogram_font ===
                                                  'college' ||
                                                lineItem.monogram_font ===
                                                  'heritage' ||
                                                lineItem.monogram_font ===
                                                  'decorative') && (
                                                <div>
                                                  SHADOW:{' '}
                                                  {lineItem.monogram_shadow}
                                                </div>
                                              )}
                                              <div>
                                                POSITION:{' '}
                                                {lineItem.monogram_position}
                                              </div>
                                            </div>
                                          </a>
                                        )}
                                        {lineItem.message && (
                                          <div
                                            style={{
                                              opactity: 0.8,
                                              fontSize: 10,
                                            }}
                                          >
                                            <div>
                                              message: {lineItem.message}
                                            </div>
                                          </div>
                                        )}
                                        {lineItem.pet &&
                                          lineItem.monogram_airtable_line &&
                                          airtableData &&
                                          lineItem.monogram_pets &&
                                          lineItem.monogram_airtable_line.map(
                                            (val, key) => {
                                              const currentAirtableFind =
                                                airtableData.find(
                                                  (airtablerecord) =>
                                                    airtablerecord.id === val
                                                );
                                              if (!currentAirtableFind)
                                                return <></>;
                                              const currentAirtable =
                                                currentAirtableFind['fields'];
                                              return (
                                                <div>
                                                  <a
                                                    href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                                                    target="_blank"
                                                  >
                                                    <div>
                                                      POSITION:{' '}
                                                      {
                                                        lineItem.monogram_position
                                                      }
                                                    </div>
                                                    <div>
                                                      {
                                                        JSON.parse(
                                                          atob(
                                                            lineItem.monogram_pets
                                                          )
                                                        )[key].name
                                                      }{' '}
                                                      {
                                                        currentAirtable[
                                                          'Status'
                                                        ]
                                                      }
                                                    </div>
                                                    <div>
                                                      <img
                                                        src={
                                                          currentAirtable[
                                                            'Completed illustration'
                                                          ] === undefined
                                                            ? currentAirtable[
                                                                'Provided Image'
                                                              ][0][
                                                                'thumbnails'
                                                              ]['small']['url']
                                                            : currentAirtable[
                                                                'Completed illustration'
                                                              ][0][
                                                                'thumbnails'
                                                              ]['small']['url']
                                                        }
                                                      />
                                                    </div>
                                                  </a>
                                                </div>
                                              );
                                            }
                                          )}
                                      </Col>
                                      <Col sm="2" className="p-3">
                                        {lineItem.quantity}x ${lineItem.price}
                                      </Col>
                                      {((lineItem.fulfillment_status ===
                                        'unfulfilled' &&
                                        (lineItem.monogram == false ||
                                          lineItem.monogram == undefined)) ||
                                        (lineItem.fulfillment_status ===
                                          'to-be-monogrammed' &&
                                          lineItem.monogram === true) ||
                                        lineItem.fulfillment_status !==
                                          'deleted') && (
                                        <Col sm="2" className="p-3">
                                          <UncontrolledDropdown>
                                            <DropdownToggle
                                              href="#"
                                              className="card-drop"
                                              tag="i"
                                            >
                                              <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                              {((lineItem.fulfillment_status ===
                                                'unfulfilled' &&
                                                (lineItem.monogram == false ||
                                                  lineItem.monogram ==
                                                    undefined)) ||
                                                (lineItem.fulfillment_status ===
                                                  'to-be-monogrammed' &&
                                                  lineItem.monogram === true) ||
                                                lineItem.fulfillment_status ===
                                                  'awaiting-stock') && (
                                                <DropdownItem
                                                  onClick={() =>
                                                    handleEditLineItem(lineItem)
                                                  }
                                                  href="#"
                                                >
                                                  {' '}
                                                  Edit{' '}
                                                </DropdownItem>
                                              )}
                                              {lineItem.fulfillment_status !==
                                                'deleted' && (
                                                <DropdownItem
                                                  onClick={() =>
                                                    handleDeleteLineItem(
                                                      lineItem._id
                                                    )
                                                  }
                                                  href="#"
                                                >
                                                  {' '}
                                                  Delete{' '}
                                                </DropdownItem>
                                              )}
                                              {lineItem.fulfillment_status ===
                                                'awaiting-stock' && (
                                                <DropdownItem
                                                  onClick={() =>
                                                    handleRemoveAwaitingStock(
                                                      lineItem._id
                                                    )
                                                  }
                                                  href="#"
                                                >
                                                  {' '}
                                                  Remove from awaiting stock{' '}
                                                </DropdownItem>
                                              )}
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        </Col>
                                      )}
                                    </Row>
                                  );
                                })}
                          </Col>
                          <Button
                            onClick={toggleAddModalEdit}
                            color="primary"
                            style={{ marginTop: 32 }}
                          >
                            {' '}
                            +Add Line Item{' '}
                          </Button>
                        </Row>
                        <Row></Row>
                      </TabPane>
                      <TabPane tabId="2" className="p-3">
                        <Row>
                          <Col sm="12">
                            {order?.line_items && (
                              <UnfulfilledLineItems
                                shipping_address={order.shipping_address}
                                postcode={order.shipping_address.zip}
                                pick_status={order.pick_status}
                                findSKULevel={findSKULevel}
                                findUpcomingStock={findUpcomingStock}
                                findSKUStatus={findSKUStatus}
                                printDeliveryLabels={printDeliveryLabels}
                                getVariant={getVariant}
                                distanceFromHQ={order.distanceFromHQ}
                                contains_monogram={order.contains_monogram}
                                refresh={refreshData}
                                shipping_type={order?.shipping_type}
                                order_source={order?.order_source}
                                orderName={order.name}
                                orderID={order._id}
                                lineItems={order.line_items
                                  .filter(
                                    (val) =>
                                      val.fulfillment_status ===
                                        'unfulfilled' ||
                                      (val.fulfillment_status ===
                                        'awaiting-stock' &&
                                        val.monogram === false)
                                  )
                                  .filter((val) => val.sku !== undefined)}
                              />
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3" className="p-3">
                        <Row>
                          <Col sm="12">
                            <MonogramLineItems
                              airtableData={airtableData}
                              refresh={refreshData}
                              order={order}
                              getVariant={getVariant}
                              findSKULevel={findSKULevel}
                              findUpcomingStock={findUpcomingStock}
                              findSKUStatus={findSKUStatus}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4" className="p-3">
                        <Row>
                          <Col sm="12">
                            {order?.line_items &&
                              order.line_items
                                .filter((val) => val.sku !== undefined)
                                .filter(
                                  (val) =>
                                    val.fulfillment_status === 'fulfilled'
                                )
                                .map((lineItem) => {
                                  return (
                                    <Row>
                                      <Col sm="2">
                                        <div
                                          style={{
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <img
                                            src={
                                              getVariant(lineItem.sku)?.image
                                            }
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="5" className="p-3">
                                        <h6>
                                          {getVariant(lineItem.sku)?.name}
                                        </h6>
                                        <div>
                                          <Badge
                                            className={
                                              'font-size-12 badge-soft-' +
                                              fulfillmentToBadge(
                                                lineItem.fulfillment_status
                                              )
                                            }
                                            color={fulfillmentToBadge(
                                              lineItem.fulfillment_status
                                            )}
                                            pill
                                          >
                                            {lineItem.fulfillment_status
                                              ? lineItem.fulfillment_status
                                                  .replace('_', ' ')
                                                  .toUpperCase()
                                              : 'UN-FULFILLED'}
                                          </Badge>
                                        </div>
                                        <div>
                                          SKU:{' '}
                                          <SKUToolTip
                                            sku={lineItem.sku}
                                            findSKULevel={findSKULevel}
                                            findUpcomingStock={
                                              findUpcomingStock
                                            }
                                            findSKUStatus={findSKUStatus}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="2" className="p-3">
                                        {lineItem.monogram && !lineItem.pet && (
                                          <a
                                            href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                                              lineItem.monogram_text
                                            )}-${lineItem.monogram_color}-${
                                              lineItem.monogram_shadow || ''
                                            }-undefined${
                                              lineItem.sku.startsWith('IPC')
                                                ? `-IPC-${lineItem.monogram_position}`
                                                : ''
                                            }${
                                              lineItem.sku.startsWith('BOT') &&
                                              lineItem.monogram_position ===
                                                'Top'
                                                ? '-Lid'
                                                : ''
                                            }.pdf?avoidTheCaches=1`}`}
                                            target="_blank"
                                          >
                                            <div
                                              style={{
                                                opactity: 0.8,
                                                fontSize: 10,
                                              }}
                                            >
                                              <div>
                                                TEXT: {lineItem.monogram_text}
                                              </div>
                                              <div>
                                                FONT: {lineItem.monogram_font}
                                              </div>
                                              <div>
                                                COLOUR:{' '}
                                                {lineItem.monogram_color}
                                              </div>
                                              {(lineItem.monogram_font ===
                                                'block' ||
                                                lineItem.monogram_font ===
                                                  'college' ||
                                                lineItem.monogram_font ===
                                                  'heritage' ||
                                                lineItem.monogram_font ===
                                                  'decorative') && (
                                                <div>
                                                  SHADOW:{' '}
                                                  {lineItem.monogram_shadow}
                                                </div>
                                              )}
                                              <div>
                                                POSITION:{' '}
                                                {lineItem.monogram_position}
                                              </div>
                                            </div>
                                          </a>
                                        )}
                                        {lineItem.pet &&
                                          lineItem.monogram_airtable_line &&
                                          lineItem.monogram_airtable_line.map(
                                            (val, key) => (
                                              <a
                                                href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                                                target="_blank"
                                              >
                                                PET {key + 1}
                                              </a>
                                            )
                                          )}
                                        {lineItem.message && (
                                          <div
                                            style={{
                                              opactity: 0.8,
                                              fontSize: 10,
                                            }}
                                          >
                                            <div>
                                              message: {lineItem.message}
                                            </div>
                                          </div>
                                        )}
                                      </Col>
                                      <Col sm="2" className="p-3">
                                        {lineItem.quantity}x ${lineItem.price}
                                      </Col>
                                    </Row>
                                  );
                                })}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="5" className="p-3">
                        <Row>
                          <Col sm="12">
                            {order?.line_items && (
                              <SplitLineItems
                                printDeliveryLabels={printDeliveryLabels}
                                getVariant={getVariant}
                                distanceFromHQ={order.distanceFromHQ}
                                contains_monogram={order.contains_monogram}
                                refresh={refreshData}
                                shipping_type={order?.shipping_type}
                                order_source={order?.order_source}
                                orderName={order.name}
                                orderID={order._id}
                                lineItems={order.line_items.filter(
                                  (val) => val.sku !== undefined
                                )}
                                findSKULevel={findSKULevel}
                                findUpcomingStock={findUpcomingStock}
                                findSKUStatus={findSKUStatus}
                              />
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12"></Col>
            </Row>
          </Col>
          <Col s="12" md="4">
            <div style={{ position: 'relative', height: '100%' }}>
              <div id="stickyTest" style={{ position: 'sticky', top: 150 }}>
                <CommentHistory
                  overrideRefresh={refresh}
                  title="Notes"
                  initCommentType={'Order'}
                  initOrderName={order?.name}
                />
                <Card>
                  <CardBody>
                    {order?.order_source !== 'tmall' && (
                      <>
                        <h4 className="card-title mb-4">Customer Info</h4>
                        <h6 style={{ marginBottom: 16 }}>
                          {' '}
                          {`${order?.customer?.first_name} ${order?.customer?.last_name}`}{' '}
                        </h6>
                        <div>
                          {' '}
                          <span style={{ opacity: 0.5 }}>EMAIL:</span>{' '}
                          {order?.customer?.email}{' '}
                        </div>
                        <br />
                      </>
                    )}
                    <h6 style={{ marginBottom: 16 }}>
                      {' '}
                      Shipping Address -{' '}
                      <a href="#" onClick={handleEditShipping}>
                        edit
                      </a>
                    </h6>
                    <div> {order?.shipping_address?.name} </div>
                    <div> {order?.shipping_address?.address1} </div>
                    <div> {order?.shipping_address?.address2 || ''} </div>
                    <div>
                      {' '}
                      {`${order?.shipping_address?.city || ''} ${
                        order?.shipping_address?.province || ''
                      } ${order?.shipping_address?.zip || ''}`}{' '}
                    </div>
                    <div> {order?.shipping_address?.country || ''} </div>
                    <div> {order?.shipping_address?.phone || ''} </div>
                    <PreviousOrders orderID={orderID} />
                  </CardBody>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div style={{ display: 'none' }}>
        <PackingSlip order={order} ref={packingSlipRef} />
      </div>
      {order.contains_monogram && (
        <div style={{ display: 'none' }}>
          <PrintPage
            ref={packingLabelsRef}
            monogramRecords={labelData}
            monogram={true}
          />
        </div>
      )}
      <div style={{ display: 'none' }}>
        <PrintPage
          ref={nonpackingLabelsRef}
          monogramRecords={nonLabelData}
          monogram={false}
        />
      </div>
      <div style={{ display: 'none' }}>
        <PrintPageDeliveries
          ref={deliveryLabels}
          labels={order.line_items.map((val) => ({ quantity: val.quantity }))}
          name={order.shipping_address.name}
          address1={order.shipping_address.address1}
          city={order.shipping_address.city}
          zip={order.shipping_address.zip}
          phone={order.shipping_address.phone}
        />
      </div>
      <Modal
        isOpen={modalEdit}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        size="lg"
        toggle={togglemodalEdit}
      >
        <EditModal
          addUniquePet={addUniquePet}
          order={order}
          allUniquePets={allUniquePets}
          orderID={orderID}
          editLineItem={editLineItem}
          refresh={refreshData}
          togglemodal={togglemodalEdit}
        />
      </Modal>

      <Modal
        isOpen={modalAssignedUser}
        role="dialog"
        autoFocus={true}
        className="exampleModal"
        tabindex="-1"
        size="md"
        toggle={handleToggleAssignedUser}
      >
        <AssignedUserModal
          users={userList}
          defaultChecked={data?.assigned_user?._id}
          handleAssignedUser={handleAssignedUser}  
         
        ></AssignedUserModal>
      </Modal>

      <Modal
        isOpen={isPopupOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        size="lg"
        toggle={toggleWizardComponent}
      >
        <OrderWizardComponent
          findSKULevel={findSKULevel}
          refreshOrder={refreshData}
          orderID={orderID}
          togglemodal={toggleWizardComponent}
          order={order}
          handleFlag={handleFlag}
        />
      </Modal>

      <Modal
        isOpen={shippingModalEdit}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        size="lg"
        toggle={toggleShippingNodalEdit}
      >
        <EditModalShipping
          orderID={orderID}
          order={order}
          refresh={refreshData}
          togglemodal={toggleShippingNodalEdit}
        />
      </Modal>

      <Modal
        isOpen={returnModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        size="lg"
        toggle={togglemodalReturn}
      >
        <ReturnsModal
          locale={
            ['shopify_us', 'goop'].includes(order.order_source) ? 'US' : 'AU'
          }
          orderID={orderID}
          order={order}
          refresh={refreshData}
          togglemodal={togglemodalReturn}
        />
      </Modal>

      <Modal
        isOpen={returnModalOld}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        size="lg"
        toggle={togglemodalReturnOld}
      >
        <ReturnsModalOld
          locale={
            ['shopify_us', 'goop'].includes(order.order_source) ? 'US' : 'AU'
          }
          orderID={orderID}
          order={order}
          refresh={refreshData}
          togglemodal={togglemodalReturnOld}
        />
      </Modal>

      <Modal
        isOpen={addModalEdit}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        size="lg"
        toggle={toggleAddModalEdit}
      >
        {addModalEdit === true && (
          <AddModal
            orderID={orderID}
            editLineItem={editLineItem}
            refresh={refreshData}
            togglemodal={toggleAddModalEdit}
          />
        )}
      </Modal>

      <Modal
        isOpen={modalFlag}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        size="lg"
        toggle={togglemodalFlag}
      >
        <FlaggedModal
          orderID={orderID}
          flagLineItem={flagLineItem}
          refresh={refreshData}
          togglemodal={togglemodalFlag}
        />
      </Modal>

      <Modal
        isOpen={cancelOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        size="lg"
        toggle={toggleCancelOpen}
      >
        <ModalBody>
          <h3>CANCELLING ORDER</h3>
        </ModalBody>
        <ModalBody>
          Are you sure you want to cancel this order? (this can't be undone).
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleCancel}
            color="danger"
            className="btn-lg btn-rounded"
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const returnTypes = ['REPAIR', 'REPLACEMENT', 'RETURN', 'EXCHANGE'];

const defaultReasons = {
  REPAIR: [
    'TSA_LOCK',
    'HANDLE_TELESCOPIC',
    'HANDLE_CARRY',
    'TRUNK_LOCK',
    'SNAPSLEEVE_FIDLOCK',
    'TRUNK_CLIP',
    'LUGGAGE_FEET',
    'MONOGRAM_REPRINT',
    'ZIP',
  ],
  EXCHANGE: ['Change size', 'Change product', 'Change colour', 'Other'],
  RETURN: [
    'Change of Mind',
    'Too small',
    'Too Large',
    'Colour',
    'Quality',
    'Warranty',
    'Other',
  ],
  REPLACEMENT: [
    'Wheels',
    'Handle',
    'Zip',
    'Shell',
    'Lining',
    'Snapsleeve',
    'Strap',
    'Power bank',
    'Other',
  ],
};

const ReturnsModal = ({
  togglemodal,
  refresh,
  order,
  orderID,
  locale = 'US',
}) => {
  const initParcel = {
    lineItems: [],
    lineItemsFlat: undefined,
    product: undefined,
    productData: undefined,
    parcelSize: undefined,
    quantity: 1,
  };

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const [parcelData, setParcelData] = useState([initParcel]);
  const [returnItems, setReturnItems] = useState([]);
  const [refundAmount, setRefundAmount] = useState(0);
  const [returnType, setReturnType] = useState({
    label: returnTypes[0],
    value: returnTypes[0],
  });
  const [reason, setReason] = useState({
    label: defaultReasons[returnTypes[0]][0],
    value: defaultReasons[returnTypes[0]][0],
  });
  const [description, setDescription] = useState();
  useEffect(() => {
    setReason({
      label: defaultReasons[returnType.value][0],
      value: defaultReasons[returnType.value][0],
    });
    setDescription('');
  }, [returnType]);

  const [data, state] = useQuery({
    url: `/product`,
    refresh,
  });
  const productData = data && data.data;

  const [dataParcel, stateParcel] = useQuery({
    url: `/orders/fetchAllParcels`,
  });
  const parcels = dataParcel;

  useEffect(() => {
    setParcelData((prev) => {
      const tempReturnItems = selectedLineItems
        .map((lineItem) =>
          Array.from(Array(lineItem.quantity)).map((_, i) => ({
            sku: lineItem.sku,
            quantity: 1,
            id: lineItem.id,
            dupeId: `${lineItem.id}-${i}`,
            selected: undefined,
          }))
        )
        .flat();

      if (!productData || !parcels) {
        return [initParcel];
      }

      let flatItems = tempReturnItems.map((item) => {
        const rootSku = item.sku
          .split('-')
          .filter((val) => {
            return val !== 'P' && val !== 'LE';
          })
          .join('-');
        const filteredProducts = productData
          .map((product, productId) => {
            const filteredVariants = product.variants.filter(
              (variant) => variant.sku === rootSku
            );
            if (filteredVariants.length === 0) {
              return undefined;
            }
            return { id: productId, variant: filteredVariants };
          })
          .filter((val) => val !== undefined);
        let p, pd;
        if (filteredProducts.length > 0) {
          const selectedProduct = productData[filteredProducts[0].id];
          p = selectedProduct && selectedProduct._id;
          pd = { label: selectedProduct.name, value: selectedProduct._id };
        }

        let filteredParcelSize = parcels.filter(
          (parcel) => `${parcel.product._id}` == `${p}`
        );
        let parcelSize, parcelSizeData, cubic;

        let parcel =
          filteredParcelSize.length > 0 ? filteredParcelSize[0] : parcels[0];
        parcelSize = parcel._id;
        parcelSizeData = {
          label: `${parcel.product.name} - ${parcel.length}x${parcel.width}x${parcel.depth} ${parcel.weight}kg`,
          value: parcel._id,
        };
        cubic = parcel.length * parcel.width * parcel.depth;

        return {
          lineItems: [item.dupeId],
          lineItemsFlat: [{ label: item.sku, value: item.dupeId }],
          product: p,
          productData: pd,
          parcelSize: parcelSize,
          parcelSizeData: parcelSizeData,
          quantity: 1,
          cubic,
          sku: item.sku,
        };
      });

      return flatItems;
    });
    setReturnItems(
      selectedLineItems
        .map((lineItem) =>
          Array.from(Array(lineItem.quantity)).map((_, i) => ({
            sku: lineItem.sku,
            quantity: 1,
            id: lineItem.id,
            dupeId: `${lineItem.id}-${i}`,
            selected: undefined,
          }))
        )
        .flat()
    );
  }, [selectedLineItems, productData]);

  useEffect(() => {
    setSelectedLineItems(
      order.line_items.map((lineItem) => ({
        sku: lineItem.sku.split('-P').join(''),
        quantity: lineItem.quantity,
        id: lineItem._id,
      }))
    );
  }, [order, parcels]);

  useEffect(() => {
    if (returnItems) {
      setReturnItems((prev) => {
        return prev.map((returnItem, rID) => {
          let parcelReduced = parcelData.map((parcel, parcelId) => ({
            id: parcelId,
            lineItem: parcel.lineItems.find((val) => val === returnItem.dupeId),
          }));
          parcelReduced = parcelReduced.filter(
            (val) => val.lineItem !== undefined
          );
          let updatedReturnItem = { ...returnItem };
          if (parcelReduced.length > 0) {
            updatedReturnItem.selected = parcelReduced[0].id;
          } else {
            updatedReturnItem.selected = undefined;
          }
          return updatedReturnItem;
        });
      });
    }
  }, [parcelData, step]);

  const addParcel = () => {
    setParcelData((prev) => {
      let updatedLines = [...prev];
      updatedLines.push(initParcel);
      return updatedLines;
    });
  };

  const deleteParcel = (id) => {
    setParcelData((prev) => {
      return prev.filter((val, parcelId) => parcelId !== id);
    });
  };

  const handleSelectProduct = (selectedProduct, id) => {
    setParcelData((prev) => {
      let updatedLines = [...prev];
      updatedLines[id].parcelSize = selectedProduct && selectedProduct.value;
      updatedLines[id].parcelSizeData = selectedProduct;
      return updatedLines;
    });
  };

  const handleSelectSkus = (selectedSkus, id) => {
    setParcelData((prev) => {
      let updatedLines = [...prev];
      updatedLines[id].lineItems =
        (Array.isArray(selectedSkus) && selectedSkus.map((a) => a.value)) || [];
      updatedLines[id].lineItemsFlat = selectedSkus;

      if (updatedLines[id].lineItems.length === 1) {
        const selectedLineId = updatedLines[id].lineItems[0];
        const selectedLine = returnItems.find(
          (val) => val.dupeId === selectedLineId
        );
        const rootSku = selectedLine.sku
          .split('-')
          .filter((val) => {
            return val !== 'P' && val !== 'LE';
          })
          .join('-');
        const filteredProducts = productData
          .map((product, productId) => {
            const filteredVariants = product.variants.filter(
              (variant) => variant.sku === rootSku
            );
            if (filteredVariants.length === 0) {
              return undefined;
            }
            return { id: productId, variant: filteredVariants };
          })
          .filter((val) => val !== undefined);

        if (filteredProducts.length > 0) {
          const selectedProduct = productData[filteredProducts[0].id];
          updatedLines[id].product = selectedProduct && selectedProduct._id;
          updatedLines[id].productData = {
            label: selectedProduct.name,
            value: selectedProduct._id,
          };
        }
      }
      return updatedLines;
    });
  };

  const alterQuantity = (index, quantity) => {
    if (
      index >= 0 &&
      index <= selectedLineItems.length - 1 &&
      Number.isInteger(quantity) &&
      quantity <= order.line_items[index].quantity &&
      quantity >= 0
    ) {
      setSelectedLineItems((prev) => {
        let updatedLines = [...prev];
        updatedLines[index].quantity = quantity;
        return updatedLines;
      });
    }
  };

  const handleSave = () => {
    const proccessedParcels = parcelData.map((parcel) => {
      const lineItems = parcel.lineItems.map((lineID) =>
        returnItems.find((val) => val.dupeId === lineID)
      );
      const parcelSize = parcel.parcelSize;
      return {
        lineItems,
        parcelSize,
        quantity: 1,
      };
    });

    const exportData = {
      orderID: orderID,
      proccessedParcels: proccessedParcels,
      reason: reason.value,
      description,
      refundAmount,
    };

    setLoading(true);
    superagent
      .post(`${backendUrl}/returns/createReturnFromOrder/`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if (loading === true) {
    return (
      <div>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>{locale} Return</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>
          {locale} Return - {step === 0 ? 'Select Line Items' : 'Parcel Sizes'}
        </ModalHeader>
        <ModalBody>
          <div className="text-muted mb-2">
            {step === 0
              ? 'Please select which SKUs and quantities you would like to proccess for the return.'
              : 'Please select which parcel sizes you would like for each sku (if you are nesting put them in the same parcel).'}
          </div>
          <div className="">
            {step === 0 ? (
              <>
                <div>
                  {selectedLineItems.map((lineItem, id) => (
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-4 col-form-label"
                      >
                        {' '}
                        {lineItem.sku}{' '}
                      </label>
                      <div className="col-md-8">
                        <Input
                          id={`line-item-${lineItem.sku}-${id}`}
                          className="mb-2"
                          type="number"
                          step="1"
                          value={lineItem.quantity}
                          onChange={(e) => {
                            alterQuantity(id, Number(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="pt-3 mt-3 border-top">
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-4 col-form-label"
                    >
                      {' '}
                      Reason{' '}
                    </label>
                    <div className="col-md-8">
                      <Select
                        classNamePrefix="select2-selection"
                        value={reason}
                        onChange={(val) => {
                          setReason(val);
                        }}
                        options={defaultReasons[returnType.value].map(
                          (val) => ({ label: val, value: val })
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-4 col-form-label"
                    >
                      {' '}
                      Description{' '}
                    </label>
                    <div className="col-md-8">
                      <Input
                        placeholder="Reason Description"
                        className="mb-2"
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-4">SKUS</div>
                  <div className="col-md-5">PARCEL SIZE</div>
                  <div className="col-md-1"></div>
                  <div className="col-md-1"></div>
                </div>

                {parcelData.map((parcel, id) => {
                  return (
                    <div className="form-group row">
                      <div className="col-md-4">
                        <Select
                          classNamePrefix="select2-selection"
                          value={parcel.lineItemsFlat}
                          onChange={(val) => {
                            handleSelectSkus(val, id);
                          }}
                          options={returnItems
                            .filter(
                              (val) =>
                                val.selected === id ||
                                val.selected === undefined
                            )
                            .map((val, id) => ({
                              label: val.sku,
                              value: val.dupeId,
                            }))}
                          isMulti
                        />
                      </div>
                      <div className="col-md-5">
                        {parcels && (
                          <Select
                            classNamePrefix="select2-selection"
                            value={parcel.parcelSizeData}
                            onChange={(val) => {
                              handleSelectProduct(val, id);
                            }}
                            options={parcels.map((val, id) => ({
                              label: `${val.product.name} - ${val.length}x${val.width}x${val.depth} ${val.weight}kg`,
                              value: val._id,
                            }))}
                          />
                        )}
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-1">
                        <Button color="danger" onClick={() => deleteParcel(id)}>
                          {' '}
                          X{' '}
                        </Button>
                      </div>
                    </div>
                  );
                })}
                {returnItems.filter((val) => val.selected === undefined)
                  .length > 0 && (
                  <Button color="primary" onClick={addParcel}>
                    {' '}
                    Add Line +{' '}
                  </Button>
                )}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          {step === 0 ? (
            <Button color="success" onClick={() => setStep(1)}>
              {' '}
              Next{' '}
            </Button>
          ) : (
            <>
              <Button color="secondary" onClick={() => setStep(0)}>
                {' '}
                Back{' '}
              </Button>
              <Button
                color="success"
                disabled={
                  returnItems.filter((val) => val.selected === undefined)
                    .length > 0
                }
                onClick={handleSave}
              >
                {' '}
                Send{' '}
              </Button>
            </>
          )}
        </ModalFooter>
      </div>
    </div>
  );
};

const ReturnsModalOld = ({
  togglemodal,
  refresh,
  order,
  orderID,
  locale = 'US',
}) => {
  const initParcel = {
    lineItems: [],
    lineItemsFlat: undefined,
    product: undefined,
    productData: undefined,
    parcelSize: undefined,
    quantity: 1,
  };

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const [parcelData, setParcelData] = useState([initParcel]);
  const [returnItems, setReturnItems] = useState([]);

  const [data, state] = useQuery({
    url: `/product`,
    refresh,
  });
  const productData = data && data.data;

  const [dataParcel, stateParcel] = useQuery({
    url: `/orders/fetchAllParcels`,
  });
  const parcels = dataParcel;

  useEffect(() => {
    setParcelData((prev) => {
      const tempReturnItems = selectedLineItems
        .map((lineItem) =>
          Array.from(Array(lineItem.quantity)).map((_, i) => ({
            sku: lineItem.sku,
            quantity: 1,
            id: lineItem.id,
            dupeId: `${lineItem.id}-${i}`,
            selected: undefined,
          }))
        )
        .flat();

      if (!productData || !parcels) {
        return [initParcel];
      }

      let flatItems = tempReturnItems.map((item) => {
        const rootSku = item.sku
          .split('-')
          .filter((val) => {
            return val !== 'P' && val !== 'LE';
          })
          .join('-');
        const filteredProducts = productData
          .map((product, productId) => {
            const filteredVariants = product.variants.filter(
              (variant) => variant.sku === rootSku
            );
            if (filteredVariants.length === 0) {
              return undefined;
            }
            return { id: productId, variant: filteredVariants };
          })
          .filter((val) => val !== undefined);
        let p, pd;
        if (filteredProducts.length > 0) {
          const selectedProduct = productData[filteredProducts[0].id];
          p = selectedProduct && selectedProduct._id;
          pd = { label: selectedProduct.name, value: selectedProduct._id };
        }

        let filteredParcelSize = parcels.filter(
          (parcel) => `${parcel.product._id}` == `${p}`
        );
        let parcelSize, parcelSizeData, cubic;

        let parcel =
          filteredParcelSize.length > 0 ? filteredParcelSize[0] : parcels[0];
        parcelSize = parcel._id;
        parcelSizeData = {
          label: `${parcel.product.name} - ${parcel.length}x${parcel.width}x${parcel.depth} ${parcel.weight}kg`,
          value: parcel._id,
        };
        cubic = parcel.length * parcel.width * parcel.depth;

        return {
          lineItems: [item.dupeId],
          lineItemsFlat: [{ label: item.sku, value: item.dupeId }],
          product: p,
          productData: pd,
          parcelSize: parcelSize,
          parcelSizeData: parcelSizeData,
          quantity: 1,
          cubic,
          sku: item.sku,
        };
      });

      return flatItems;
    });
    setReturnItems(
      selectedLineItems
        .map((lineItem) =>
          Array.from(Array(lineItem.quantity)).map((_, i) => ({
            sku: lineItem.sku,
            quantity: 1,
            id: lineItem.id,
            dupeId: `${lineItem.id}-${i}`,
            selected: undefined,
          }))
        )
        .flat()
    );
  }, [selectedLineItems, productData]);

  useEffect(() => {
    setSelectedLineItems(
      order.line_items.map((lineItem) => ({
        sku: lineItem.sku,
        quantity: lineItem.quantity,
        id: lineItem._id,
      }))
    );
  }, [order, parcels]);

  useEffect(() => {
    if (returnItems) {
      setReturnItems((prev) => {
        return prev.map((returnItem, rID) => {
          let parcelReduced = parcelData.map((parcel, parcelId) => ({
            id: parcelId,
            lineItem: parcel.lineItems.find((val) => val === returnItem.dupeId),
          }));
          parcelReduced = parcelReduced.filter(
            (val) => val.lineItem !== undefined
          );
          let updatedReturnItem = { ...returnItem };
          if (parcelReduced.length > 0) {
            updatedReturnItem.selected = parcelReduced[0].id;
          } else {
            updatedReturnItem.selected = undefined;
          }
          return updatedReturnItem;
        });
      });
    }
  }, [parcelData, step]);

  const addParcel = () => {
    setParcelData((prev) => {
      let updatedLines = [...prev];
      updatedLines.push(initParcel);
      return updatedLines;
    });
  };

  const deleteParcel = (id) => {
    setParcelData((prev) => {
      return prev.filter((val, parcelId) => parcelId !== id);
    });
  };

  const handleSelectProduct = (selectedProduct, id) => {
    setParcelData((prev) => {
      let updatedLines = [...prev];
      updatedLines[id].parcelSize = selectedProduct && selectedProduct.value;
      updatedLines[id].parcelSizeData = selectedProduct;
      return updatedLines;
    });
  };

  const handleSelectSkus = (selectedSkus, id) => {
    setParcelData((prev) => {
      let updatedLines = [...prev];
      updatedLines[id].lineItems =
        (Array.isArray(selectedSkus) && selectedSkus.map((a) => a.value)) || [];
      updatedLines[id].lineItemsFlat = selectedSkus;

      if (updatedLines[id].lineItems.length === 1) {
        const selectedLineId = updatedLines[id].lineItems[0];
        const selectedLine = returnItems.find(
          (val) => val.dupeId === selectedLineId
        );
        const rootSku = selectedLine.sku
          .split('-')
          .filter((val) => {
            return val !== 'P' && val !== 'LE';
          })
          .join('-');
        const filteredProducts = productData
          .map((product, productId) => {
            const filteredVariants = product.variants.filter(
              (variant) => variant.sku === rootSku
            );
            if (filteredVariants.length === 0) {
              return undefined;
            }
            return { id: productId, variant: filteredVariants };
          })
          .filter((val) => val !== undefined);

        if (filteredProducts.length > 0) {
          const selectedProduct = productData[filteredProducts[0].id];
          updatedLines[id].product = selectedProduct && selectedProduct._id;
          updatedLines[id].productData = {
            label: selectedProduct.name,
            value: selectedProduct._id,
          };
        }
      }
      return updatedLines;
    });
  };

  const alterQuantity = (index, quantity) => {
    if (
      index >= 0 &&
      index <= selectedLineItems.length - 1 &&
      Number.isInteger(quantity) &&
      quantity <= order.line_items[index].quantity &&
      quantity >= 0
    ) {
      setSelectedLineItems((prev) => {
        let updatedLines = [...prev];
        updatedLines[index].quantity = quantity;
        return updatedLines;
      });
    }
  };

  const handleSave = () => {
    const proccessedParcels = parcelData.map((parcel) => {
      const lineItems = parcel.lineItems.map((lineID) =>
        returnItems.find((val) => val.dupeId === lineID)
      );
      const parcelSize = parcel.parcelSize;
      return {
        lineItems,
        parcelSize,
        quantity: 1,
      };
    });

    const exportData = {
      orderID: orderID,
      proccessedParcels: proccessedParcels,
    };

    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/create${locale}Return/`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if (loading === true) {
    return (
      <div>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>{locale} Return</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>
          {locale} Return - {step === 0 ? 'Select Line Items' : 'Parcel Sizes'}
        </ModalHeader>
        <ModalBody>
          <div className="text-muted mb-2">
            {step === 0
              ? 'Please select which SKUs and quantities you would like to proccess for the return.'
              : 'Please select which parcel sizes you would like for each sku (if you are nesting put them in the same parcel).'}
          </div>
          <div className="">
            {step === 0 ? (
              <>
                {selectedLineItems.map((lineItem, id) => (
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-4 col-form-label"
                    >
                      {' '}
                      {lineItem.sku}{' '}
                    </label>
                    <div className="col-md-8">
                      <Input
                        id={`line-item-${lineItem.sku}-${id}`}
                        className="mb-2"
                        type="number"
                        step="1"
                        value={lineItem.quantity}
                        onChange={(e) => {
                          alterQuantity(id, Number(e.target.value));
                        }}
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-4">SKUS</div>
                  <div className="col-md-5">PARCEL SIZE</div>
                  <div className="col-md-1"></div>
                  <div className="col-md-1"></div>
                </div>

                {parcelData.map((parcel, id) => {
                  return (
                    <div className="form-group row">
                      <div className="col-md-4">
                        <Select
                          classNamePrefix="select2-selection"
                          value={parcel.lineItemsFlat}
                          onChange={(val) => {
                            handleSelectSkus(val, id);
                          }}
                          options={returnItems
                            .filter(
                              (val) =>
                                val.selected === id ||
                                val.selected === undefined
                            )
                            .map((val, id) => ({
                              label: val.sku,
                              value: val.dupeId,
                            }))}
                          isMulti
                        />
                      </div>
                      <div className="col-md-5">
                        {parcels && (
                          <Select
                            classNamePrefix="select2-selection"
                            value={parcel.parcelSizeData}
                            onChange={(val) => {
                              handleSelectProduct(val, id);
                            }}
                            options={parcels.map((val, id) => ({
                              label: `${val.product.name} - ${val.length}x${val.width}x${val.depth} ${val.weight}kg`,
                              value: val._id,
                            }))}
                          />
                        )}
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-1">
                        <Button color="danger" onClick={() => deleteParcel(id)}>
                          {' '}
                          X{' '}
                        </Button>
                      </div>
                    </div>
                  );
                })}
                {returnItems.filter((val) => val.selected === undefined)
                  .length > 0 && (
                  <Button color="primary" onClick={addParcel}>
                    {' '}
                    Add Line +{' '}
                  </Button>
                )}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          {step === 0 ? (
            <Button color="success" onClick={() => setStep(1)}>
              {' '}
              Next{' '}
            </Button>
          ) : (
            <>
              <Button color="secondary" onClick={() => setStep(0)}>
                {' '}
                Back{' '}
              </Button>
              <Button
                color="success"
                disabled={
                  returnItems.filter((val) => val.selected === undefined)
                    .length > 0
                }
                onClick={handleSave}
              >
                {' '}
                Send{' '}
              </Button>
            </>
          )}
        </ModalFooter>
      </div>
    </div>
  );
};

export const AssignedUserModal = ({ defaultChecked ,users, handleAssignedUser }) => {
  const [selectedUser, setSelectedUser] = useState(''); // State to hold the selected user
  
  const handleSelectChange = (e) => {
    setSelectedUser(e.target.value);
  };

  
  
  const handleButtonClick = () => {
    handleAssignedUser(selectedUser);
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">Assigned User</CardTitle>
      </CardHeader>
      <CardBody>
        <Label>User</Label>
        <Input 
          onChange={handleSelectChange}
          type="select" name="select"
          >
            <option>Please select</option>
          {users.map((val) => (
            <option selected={val._id === defaultChecked} value={val._id}>{val.userName}</option>
          ))}
        </Input>

        <Button 
          color="primary" 
          onClick={handleButtonClick}
          style={{ marginTop: '10px' }}
        >
          Assign User
        </Button>
      </CardBody>
    </Card>
  );
};

const EditModal = ({
  togglemodal,
  refresh,
  editLineItem,
  orderID,
  allUniquePets,
  order,
  addUniquePet,
}) => {
  const [monogramType, setMonogramType] = useState(
    editLineItem.pet === true ? 'pets' : 'text'
  );

  const [monogramPets, setMonogramPets] = useState(editLineItem.monogram_pets);
  const [monogramAirtableLines, setMonogramAirtableLines] = useState(
    editLineItem.monogram_airtable_line
  );

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  useEffect(() => {
    setLoading(true);
    superagent
      .get(
        `${backendUrl}/product/variant/${editLineItem.sku
          .split('-')
          .filter((val) => {
            return val !== 'P' && val !== 'LE';
          })
          .join('-')}/find`
      )
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          setOptions(response.body);
          setSelectedOption(editLineItem);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });

    if (editLineItem.monogram === true) {
      setMonogramText(editLineItem.monogram_text);
      setMonogramFont(editLineItem.monogram_font);
      setMonogramColor(editLineItem.monogram_color);
      setMonogramShadow(editLineItem.monogram_shadow || 'None');
      setMonogramLocation(editLineItem.monogram_position);
    }
  }, [editLineItem]);

  const handleOptionChanged = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
  };

  const [monogramText, setMonogramText] = useState('');
  const [monogramFont, setMonogramFont] = useState();
  const [monogramColor, setMonogramColor] = useState();
  const [monogramShadow, setMonogramShadow] = useState();
  const [monogramLocation, setMonogramLocation] = useState();

  const [edittingPet, setEdittingPet] = useState();

  const updateLineItem = () => {
    let newLineItem = {
      _id: editLineItem._id,
      sku: selectedOption?.sku,
      orderID: orderID,
      ...(selectedOption?.sku
        .split('-')
        .filter((val) => val !== 'P' && val !== 'LE')
        .join('-') !== selectedOption?.sku
        ? monogramType === 'text'
          ? {
              monogram_text: encodeURIComponent(monogramText),
              monogram_font: monogramFont,
              monogram_color: monogramColor,
              monogram_position: monogramLocation,
              ...(monogramFont === 'block' ||
              monogramFont === 'college' ||
              monogramFont === 'heritage' ||
              monogramFont === 'decorative'
                ? { monogram_shadow: monogramShadow }
                : {}),
              monogram: true,
              pet: false,
            }
          : {
              monogram_position: monogramLocation,
              monogram: true,
              monogram_pets: monogramPets,
              monogram_airtable_line: monogramAirtableLines,
              pet: true,
            }
        : {
            monogram: false,
          }),
    };

    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/${editLineItem._id}/editLineItem`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  let [comment_file, setCommentFile] = useState(null);
  let [imageData, setImageData] = useState();
  let [uploadedAirtableID, setUploadedAirtableID] = useState('');
  let [newPetName, setNewPetName] = useState('');

  const handleFileChange = ({ target: { files } }) => {
    const cancel = !files.length;
    if (cancel) return;

    let file = files[0];
    setCommentFile(file);

    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = function (event) {
      setImageData({
        base64: `data:${file.type};base64,${btoa(event.target.result)}`,
        filename: file.name,
      });
      event.target.value = null;
      event.target.files = null;
      setCommentFile(null);
    };
  };

  const uploadToS3 = () => {
    const uploadData = {
      petName: newPetName,
      base64Image: imageData.base64,
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    };

    options.body = JSON.stringify(uploadData);

    return new Promise((resolve, reject) => {
      fetch(
        'https://az25kdsm7h.execute-api.ap-southeast-2.amazonaws.com/production/uploadPetImagesToS3',
        options
      )
        .then((response) => response.json())
        .then((response) => {
          let base64ToString = Buffer.from(response.url, 'base64').toString();
          resolve(base64ToString);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const createAirtable = (printJobID) => {
    const uploadData = {
      printJobID: printJobID,
      OrderNumber: order.name,
      email: order.email,
    };

    return new Promise((resolve, reject) => {
      superagent
        .post(`${backendUrl}/orders/createPetAirtable/`)
        .send(uploadData)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          reject(response);
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            resolve(response.body);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const fetchAirtable = (airtableId) => {
    const uploadData = {
      airtableId,
    };

    return new Promise((resolve, reject) => {
      superagent
        .post(`${backendUrl}/orders/fetchPetAirtable/`)
        .send(uploadData)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          reject(response);
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            resolve(response.body);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const sleep = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const savePet = async () => {
    let airtableID;
    if (imageData !== undefined) {
      let printjobID = await uploadToS3();
      airtableID = (await createAirtable(printjobID)).id;
      await sleep(2000);
    } else if (uploadedAirtableID !== '') {
      airtableID = uploadedAirtableID;
    } else {
      return;
    }

    let airtableRecord = await fetchAirtable(airtableID);

    let uniquePet = {
      ...airtableRecord['fields'],
      name: newPetName,
    };

    addUniquePet(uniquePet, airtableID);
    setEdittingPet();
  };

  if (loading === true) {
    return (
      <div>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Edit Line Item</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    );
  }

  const colorType = selectedOption
    ? ['BKP', 'BPK', 'WKD', 'TOT', 'CAR', 'DBK', 'EVE'].includes(
        selectedOption?.sku.slice(0, 3)
      ) || ['EVETOT'].includes(selectedOption?.sku.slice(0, 6))
    : false;
  let colors = colorType ? newColorsBags : newColors;
  let fontsFor = colorType ? fontsBags : fonts;

  const isEmboss = selectedOption && selectedOption?.sku.includes('JETTE');
  if (isEmboss) {
    colors = embosColours;
    fontsFor = embosFonts;
  }

  const monogramTypes = {
    text: 'Text',
    pets: 'Pets',
  };

  return (
    <div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>
          {edittingPet !== undefined ? 'New pet upload' : 'Edit Line Item'}
        </ModalHeader>
        <ModalBody>
          {edittingPet !== undefined ? (
            <>
              {edittingPet.airtableId === undefined ? (
                <>
                  {uploadedAirtableID === '' &&
                    (imageData === undefined ? (
                      <FormGroup className="mb-4" row>
                        <Col
                          lg="12"
                          className="d-flex align-items-center custome-input"
                        >
                          <CustomInput
                            accept="image/*"
                            className=" ml-2"
                            type="file"
                            id="comment_file"
                            name="comment_file"
                            label={comment_file || 'Add image'}
                            onChange={(e) => handleFileChange(e)}
                          />
                        </Col>
                      </FormGroup>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => setImageData()}
                      >
                        <img
                          style={{ height: 100, width: 100 }}
                          src={imageData.base64}
                        />
                      </div>
                    ))}
                  {imageData === undefined && (
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        {' '}
                        Airtable ID{' '}
                      </label>
                      <div className="col-md-10">
                        <Input
                          placeholder="Airtable ID"
                          className="mb-2"
                          type="text"
                          value={uploadedAirtableID}
                          onChange={(e) =>
                            setUploadedAirtableID(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      {' '}
                      Pet name{' '}
                    </label>
                    <div className="col-md-10">
                      <Input
                        placeholder="Pet name"
                        className="mb-2"
                        type="text"
                        value={newPetName}
                        onChange={(e) => setNewPetName(e.target.value)}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <> {JSON.stringify(edittingPet)} </>
              )}
            </>
          ) : (
            <>
              <ProductVariantSelector
                products={options}
                setSelectedVariant={setSelectedOption}
                selectedVariant={selectedOption}
              />
              <br />
              {selectedOption &&
                selectedOption?.sku
                  .split('-')
                  .filter((val) => val !== 'P' && val !== 'LE')
                  .join('-') !== selectedOption?.sku && (
                  <div>
                    <br />
                    <h6> Monogram Data</h6>
                    <br />
                    <div className="form-group row">
                      <div className="col-md-12">
                        <Input
                          className="mb-2"
                          onChange={(e) => setMonogramType(e.target.value)}
                          value={monogramType}
                          type="select"
                          name="type"
                        >
                          <option disabled selected value>
                            {' '}
                            -- select an option --{' '}
                          </option>
                          {Object.keys(monogramTypes).map((val) => (
                            <option value={val}>{monogramTypes[val]}</option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    <br />
                    {monogramType === 'text' && (
                      <>
                        <div className="form-group row">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            {' '}
                            Text{' '}
                          </label>
                          <div className="col-md-10">
                            <Input
                              placeholder="Monogram text"
                              className="mb-2"
                              type="text"
                              value={monogramText}
                              onChange={(e) => setMonogramText(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            {' '}
                            Font{' '}
                          </label>
                          <div className="col-md-10">
                            <Input
                              className="mb-2"
                              onChange={(e) => setMonogramFont(e.target.value)}
                              value={monogramFont}
                              type="select"
                              name="select"
                            >
                              <option disabled selected value>
                                {' '}
                                -- select an option --{' '}
                              </option>
                              {Object.keys(fontsFor).map((val) => (
                                <option value={val}>{fontsFor[val]}</option>
                              ))}
                            </Input>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Main Colour
                          </label>
                          <div className="col-md-10">
                            <Input
                              className="mb-2"
                              onChange={(e) => setMonogramColor(e.target.value)}
                              value={monogramColor}
                              type="select"
                              name="select"
                            >
                              <option disabled selected value>
                                {' '}
                                -- select an option --{' '}
                              </option>
                              {Object.keys(colors).map((val) => (
                                <option
                                  style={{ color: colors[val] }}
                                  value={val}
                                >
                                  {val}
                                </option>
                              ))}
                            </Input>
                          </div>
                        </div>
                        {(monogramFont === 'block' ||
                          monogramFont === 'college' ||
                          monogramFont === 'heritage' ||
                          monogramFont === 'decorative') && (
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Shadow Colour
                            </label>
                            <div className="col-md-10">
                              <Input
                                className="mb-2"
                                onChange={(e) =>
                                  setMonogramShadow(e.target.value)
                                }
                                value={monogramShadow}
                                type="select"
                                name="select"
                              >
                                <option disabled selected value>
                                  {' '}
                                  -- select an option --{' '}
                                </option>
                                {Object.keys(newColors).map((val) => (
                                  <option
                                    style={{ color: newColors[val] }}
                                    value={val}
                                  >
                                    {val}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        )}
                        <div className="form-group row">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Location
                          </label>
                          <div className="col-md-10">
                            <Input
                              className="mb-2"
                              onChange={(e) =>
                                setMonogramLocation(e.target.value)
                              }
                              value={monogramLocation}
                              type="select"
                              name="select"
                            >
                              <option disabled selected value>
                                {' '}
                                -- select an option --{' '}
                              </option>
                              {editLineItem.sku.includes('NBK')
                                ? Object.keys(Notebooklocations).map((val) => (
                                    <option value={Notebooklocations[val]}>
                                      {Notebooklocations[val]}
                                    </option>
                                  ))
                                : editLineItem.sku.includes('TEC') ||
                                  editLineItem.sku.includes('CME')
                                ? Object.keys(TecLocations).map((val) => (
                                    <option value={TecLocations[val]}>
                                      {TecLocations[val]}
                                    </option>
                                  ))
                                : editLineItem.sku.includes('TAG')
                                ? Object.keys(TagLocations).map((val) => (
                                    <option value={TagLocations[val]}>
                                      {TagLocations[val]}
                                    </option>
                                  ))
                                : Object.keys(locations).map((val) => (
                                    <option value={locations[val]}>
                                      {locations[val]}
                                    </option>
                                  ))}
                            </Input>
                          </div>
                        </div>
                      </>
                    )}
                    {monogramType === 'pets' && (
                      <>
                        <div className="form-group row">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Location
                          </label>
                          <div className="col-md-10">
                            <Input
                              className="mb-2"
                              onChange={(e) =>
                                setMonogramLocation(e.target.value)
                              }
                              value={monogramLocation}
                              type="select"
                              name="select"
                            >
                              <option disabled selected value>
                                {' '}
                                -- select an option --{' '}
                              </option>
                              {editLineItem.sku.includes('NBK')
                                ? Object.keys(Notebooklocations).map((val) => (
                                    <option value={Notebooklocations[val]}>
                                      {Notebooklocations[val]}
                                    </option>
                                  ))
                                : editLineItem.sku.includes('TEC') ||
                                  editLineItem.sku.includes('CME')
                                ? Object.keys(TecLocations).map((val) => (
                                    <option value={TecLocations[val]}>
                                      {TecLocations[val]}
                                    </option>
                                  ))
                                : editLineItem.sku.includes('TAG')
                                ? Object.keys(TagLocations).map((val) => (
                                    <option value={TagLocations[val]}>
                                      {TagLocations[val]}
                                    </option>
                                  ))
                                : Object.keys(locations).map((val) => (
                                    <option value={locations[val]}>
                                      {locations[val]}
                                    </option>
                                  ))}
                            </Input>
                          </div>
                        </div>
                        <Row>
                          {Object.keys(allUniquePets).map((petID, key) => {
                            let pet = allUniquePets[petID];
                            let selected =
                              monogramAirtableLines.includes(petID);
                            const handleTogglePetSelect = () => {
                              if (selected) {
                                setMonogramAirtableLines((prev) =>
                                  prev.filter((val) => val !== petID)
                                );
                                setMonogramPets((prev) =>
                                  btoa(
                                    JSON.stringify(
                                      JSON.parse(atob(prev)).filter(
                                        (val) =>
                                          val.signedUrl !== pet.printJobID
                                      )
                                    )
                                  )
                                );
                              } else {
                                setMonogramAirtableLines((prev) => [
                                  ...prev,
                                  petID,
                                ]);
                                setMonogramPets((prev) =>
                                  btoa(
                                    JSON.stringify([
                                      ...(prev ? JSON.parse(atob(prev)) : []),
                                      {
                                        signedUrl: pet.printJobID,
                                        name: pet.name,
                                      },
                                    ])
                                  )
                                );
                              }
                            };

                            return (
                              <Col xl="3" sm="6" key={'_pet_' + key}>
                                <Card
                                  style={{ background: '#d5d5d5' }}
                                  onClick={handleTogglePetSelect}
                                >
                                  <CardBody style={{ position: 'relative' }}>
                                    <div
                                      style={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                        fontSize: 24,
                                      }}
                                    >
                                      <i
                                        className={
                                          selected
                                            ? 'bx bx-check-square'
                                            : 'bx bx-square'
                                        }
                                      />
                                    </div>
                                    <h5>{pet.name}</h5>
                                    <h6>{pet.Status}</h6>
                                    <img
                                      src={pet['Provided Image'][0]['url']}
                                      style={{
                                        width: 100,
                                        height: 100,
                                        objectFit: 'contain',
                                      }}
                                    />
                                    <br />
                                    <br />
                                    {/* <Button color="warning" onClick={(e) => {e.stopPropagation(); setEdittingPet({...pet, airtableId: petID})}}> Edit. </Button> */}
                                  </CardBody>
                                </Card>
                              </Col>
                            );
                          })}
                          <Col xl="3" sm="6" key={'_pet_add'}>
                            <Card style={{ background: '#d5d5d5' }}>
                              <CardBody>
                                <Button
                                  color="success"
                                  onClick={() =>
                                    setEdittingPet({ airtableId: undefined })
                                  }
                                >
                                  {' '}
                                  Add new pet.{' '}
                                </Button>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {edittingPet !== undefined && (
            <>
              <Button color="warning" onClick={() => setEdittingPet()}>
                {' '}
                Back{' '}
              </Button>
              <Button
                color="success"
                disabled={
                  !(imageData !== undefined || uploadedAirtableID !== '')
                }
                onClick={savePet}
              >
                {' '}
                Save{' '}
              </Button>
            </>
          )}
          {edittingPet === undefined &&
            (selectedOption?.sku !== editLineItem.sku ||
              (selectedOption &&
                selectedOption?.sku
                  .split('-')
                  .filter((val) => val !== 'P' && val !== 'LE')
                  .join('-') !== selectedOption?.sku)) && (
              <Button color="success" onClick={updateLineItem}>
                {' '}
                Save{' '}
              </Button>
            )}
        </ModalFooter>
      </div>
    </div>
  );
};

const EditModalShipping = ({ togglemodal, refresh, order, orderID }) => {
  const apiKey = 'AIzaSyACNNxCG6Oc9WaFfFslSu3jv5qJApU9PpI';
  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey: apiKey,
    options: {
      fields: ['address_component', 'types', 'geocode'],
      types: ['geocode'],
    },
  });

  useEffect(() => {
    const loaded = new CustomEvent('loadedPlaced', {
      detail: placePredictions.map((placeLine) => ({
        label: placeLine.description,
        value: placeLine,
      })),
    });
    document.dispatchEvent(loaded);
  }, [placePredictions]);

  const [selected, setSelected] = useState(null);

  const getPlaceData = async (placeID) => {
    return await new Promise((res, rej) => {
      const map = new window.google.maps.Map(
        document.getElementById('test-map'),
        { zoom: 15 }
      );
      var request = {
        placeId: placeID,
        fields: ['address_components'],
      };

      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(request, callback);

      function callback(place, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          res(place);
        } else {
          rej(place);
        }
      }
    });
  };

  const loadOptions = (inputValue, callback) => {
    getPlacePredictions({ input: inputValue });
    document.addEventListener('loadedPlaced', function (e) {
      callback(e.detail);
    });
  };

  const [loading, setLoading] = useState(false);

  const [shippingType, setShippingType] = useState(order.shipping_type);
  const handleShippingTypeChange = (e) => {
    setShippingType(e.target.value);
  };

  const [name, setName] = useState(order.shipping_address.name);
  const [email, setEmail] = useState(order.email);
  const [company, setCompany] = useState(order.shipping_address.company);
  const [address1, setAddress1] = useState(order.shipping_address.address1);
  const [address2, setAddress2] = useState(order.shipping_address.address2);
  const [city, setCity] = useState(order.shipping_address.city);
  const [zip, setZip] = useState(order.shipping_address.zip);
  const [phone, setPhone] = useState(order.shipping_address.phone);

  const [selectedState, setState] = useState(
    State.getStatesOfCountry(
      Country.getAllCountries().find(
        (val) => val.name === order.shipping_address.country
      )?.isoCode
    )?.find((val) => val.name === order.shipping_address.province)?.isoCode
  );
  const [selectedCountry, setCountry] = useState(
    Country.getAllCountries().find(
      (val) => val.name === order.shipping_address.country
    )?.isoCode
  );

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let newState;
    try {
      newState = State.getStatesOfCountry(e.target.value)[0].isoCode;
    } catch {}
    setState(newState);
  };

  useEffect(() => {
    if (selected) {
      getPlaceData(selected.value.place_id).then((res) => {
        let items = res.address_components;
        let streetNumberGoogleAPI,
          streetAddressGoogleAPI,
          countryGoogleAPI,
          stateGoogleAPI,
          unitNoGoogleAPI,
          suburnGoogleAPI,
          postcodeGoogleAPI;

        items.map((item) => {
          if (item.types.includes('subpremise')) {
            unitNoGoogleAPI = item.long_name;
          }
          if (item.types.includes('street_number')) {
            streetNumberGoogleAPI = item.long_name;
          }
          if (item.types.includes('route')) {
            streetAddressGoogleAPI = item.long_name;
          }
          if (item.types.includes('postal_code')) {
            postcodeGoogleAPI = item.long_name;
          }
          if (item.types.includes('locality')) {
            suburnGoogleAPI = item.long_name;
          }
          if (item.types.includes('administrative_area_level_1')) {
            stateGoogleAPI = item.long_name;
          }
          if (item.types.includes('country')) {
            countryGoogleAPI = item.long_name;
          }
        });

        setCountry(
          Country.getAllCountries().find((val) => val.name === countryGoogleAPI)
            ?.isoCode
        );
        setState(
          State.getStatesOfCountry(
            Country.getAllCountries().find(
              (val) => val.name === countryGoogleAPI
            )?.isoCode
          )?.find((val) => val.name === stateGoogleAPI)?.isoCode
        );
        setCity(suburnGoogleAPI === undefined ? '' : suburnGoogleAPI);
        setZip(postcodeGoogleAPI === undefined ? '' : postcodeGoogleAPI);
        setAddress2('');
        if (streetAddressGoogleAPI) {
          if (streetNumberGoogleAPI) {
            if (unitNoGoogleAPI) {
              setAddress1(
                unitNoGoogleAPI +
                  '/' +
                  streetNumberGoogleAPI +
                  ' ' +
                  streetAddressGoogleAPI
              );
            } else {
              setAddress1(streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI);
            }
          } else {
            setAddress1(streetAddressGoogleAPI);
          }
        } else {
          setAddress1('');
        }
        setSelected('');
      });
    }
  }, [selected]);

  const handleSave = () => {
    const exportData = {
      shippingType,
      name,
      email,
      company,
      address1,
      address2,
      city,
      zip,
      phone,
      province: State.getStateByCodeAndCountry(selectedState, selectedCountry)
        .name,
      country: Country.getCountryByCode(selectedCountry).name,
    };

    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/${order._id}/editShipping/`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const makeUnqiue = () => {
    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/${order._id}/makeShippingUnique/`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if (loading === true) {
    return (
      <div>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Edit Shipping Details</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: 'none' }}>
        <div id="test-map" />
      </div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>Edit Shipping Details</ModalHeader>
        <ModalBody>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Shipping Type
            </label>
            <div className="col-md-10">
              <Input
                onChange={handleShippingTypeChange}
                type="select"
                name="select"
                id="exampleSelect"
              >
                <option selected={shippingType === 'standard'} value="standard">
                  {' '}
                  Standard{' '}
                </option>
                <option selected={shippingType === 'express'} value="express">
                  {' '}
                  Express{' '}
                </option>
                <option selected={shippingType === 'same-day'} value="same-day">
                  {' '}
                  Same Day{' '}
                </option>
                <option selected={shippingType === 'next-day'} value="next-day">
                  {' '}
                  Next Day{' '}
                </option>

                <option selected={shippingType === 'pickup'} value="pickup">
                  {' '}
                  QV Pickup{' '}
                </option>
                <option
                  selected={shippingType === 'pickup_hq'}
                  value="pickup_hq"
                >
                  {' '}
                  HQ Pickup{' '}
                </option>

                {/* <option selected={shippingType === 'pickup_galeries'} value="pickup_galeries"> HQ Galeries </option>
                <option selected={shippingType === 'pickup_collins'} value="pickup_collins"> HQ Collins </option>
                <option selected={shippingType === 'pickup_bondi'} value="pickup_bondi"> HQ Bondi </option>
                <option selected={shippingType === 'pickup_chadstone'} value="pickup_chadstone"> HQ Chadstone </option>
                <option selected={shippingType === 'pickup_crosby'} value="pickup_crosby"> HQ Crosby </option> */}

                <option selected={shippingType === 'Uber'} value="Uber">
                  {' '}
                  Uber{' '}
                </option>
                <option
                  selected={shippingType === 'Overnight'}
                  value="overnight"
                >
                  {' '}
                  Overnight{' '}
                </option>
              </Input>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Name
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                type="text"
                defaultValue={order.shipping_address.name}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Email
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                type="text"
                defaultValue={order.email}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Phone
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setPhone(e.target.value)}
                className="form-control"
                type="text"
                defaultValue={order.shipping_address.phone}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Company
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setCompany(e.target.value)}
                className="form-control"
                type="text"
                defaultValue={order.shipping_address.company}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Search Address
            </label>
            <div className="col-md-10">
              <AsyncSelect
                onClick={setSelected}
                onChange={setSelected}
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Country
            </label>
            <div className="col-md-10">
              <Input
                onChange={(e) => handleCountryChange(e)}
                type="select"
                name="select"
                id="exampleSelect"
              >
                {Country.getAllCountries().map((country) => (
                  <option
                    value={country.isoCode}
                    selected={selectedCountry === country.isoCode}
                  >
                    {' '}
                    {country.name}{' '}
                  </option>
                ))}
              </Input>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              State
            </label>
            <div className="col-md-10">
              <Input
                onChange={(e) => setState(e.target.value)}
                type="select"
                name="select"
                id="exampleSelect"
              >
                {State.getStatesOfCountry(selectedCountry).map((state) => (
                  <option
                    value={state.isoCode}
                    selected={selectedState === state.isoCode}
                  >
                    {' '}
                    {state.name}{' '}
                  </option>
                ))}
              </Input>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Address
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setAddress1(e.target.value)}
                className="form-control"
                type="text"
                value={address1}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Suburb
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setCity(e.target.value)}
                className="form-control"
                type="text"
                value={city}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Postcode
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setZip(e.target.value)}
                className="form-control"
                type="text"
                value={zip}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Apartment, suite, etc. (optional)
            </label>
            <div className="col-md-10">
              <input
                onChange={(e) => setAddress2(e.target.value)}
                className="form-control"
                type="text"
                value={address2}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={makeUnqiue}>
            {' '}
            Make Unique{' '}
          </Button>
          <Button color="success" onClick={handleSave}>
            {' '}
            Save{' '}
          </Button>
        </ModalFooter>
      </div>
    </div>
  );
};

const AddModal = ({ togglemodal, refresh, orderID }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedVariant, setSelectedVariant] = useState();

  useEffect(() => {
    setLoading(true);
    superagent
      .get(`${backendUrl}/product`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          setOptions(response.body.data);
          setSelectedProduct(response.body.data[0]._id);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleProductChanged = (e) => {
    const option = e.target.value;
    setSelectedProduct(option);
  };
  const handleVariantChanged = (e) => {
    const option = e.target.value;
    setSelectedVariant(option);
  };

  const [monogramText, setMonogramText] = useState('');
  const [monogramFont, setMonogramFont] = useState();
  const [monogramColor, setMonogramColor] = useState();
  const [monogramShadow, setMonogramShadow] = useState();
  const [monogramLocation, setMonogramLocation] = useState();

  const productObject =
    selectedProduct && options.find((val) => val._id === selectedProduct);

  const updateLineItem = () => {
    let newLineItem = {
      product: selectedProduct,
      variant: selectedVariant?.sku,
      orderID: orderID,
      ...(selectedVariant?.sku
        .split('-')
        .filter((val) => val !== 'P' && val !== 'LE')
        .join('-') !== selectedVariant?.sku
        ? {
            monogram_text: encodeURIComponent(monogramText),
            monogram_font: monogramFont,
            monogram_color: monogramColor,
            monogram_position: monogramLocation,
            ...(monogramFont === 'block' ||
            monogramFont === 'college' ||
            monogramFont === 'heritage' ||
            monogramFont === 'decorative'
              ? { monogram_shadow: monogramShadow }
              : {}),
            monogram: true,
          }
        : {
            monogram: false,
          }),
    };

    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/${orderID}/addLineItem`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if (loading === true) {
    return (
      <div>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Edit Line Item</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    );
  }

  const colorType = selectedVariant
    ? ['BKP', 'BPK', 'WKD', 'TOT', 'CAR', 'DBK', 'EVE'].includes(
        selectedVariant?.sku.slice(0, 3)
      ) || ['EVETOT'].includes(selectedVariant?.sku.slice(0, 6))
    : false;
  let colors = colorType ? newColorsBags : newColors;
  let fontsFor = colorType ? fontsBags : fonts;

  const isEmboss = selectedVariant && selectedVariant?.sku.includes('JETTE');
  console.log(productObject);
  if (isEmboss) {
    colors = embosColours;
    fontsFor = embosFonts;
  }

  const monogramTypes = {
    text: 'Text',
    pets: 'Pets',
  };

  return (
    <div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>Add Line Item</ModalHeader>
        <ModalBody>
          <ProductVariantSelector
            products={options}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
          />
          <br />
          {selectedVariant &&
            selectedVariant?.sku
              .split('-')
              .filter((val) => val !== 'P' && val !== 'LE')
              .join('-') !== selectedVariant?.sku && (
              <div>
                <h6> Monogram Data </h6>
                <Input
                  placeholder="Monogram text"
                  className="mb-2"
                  type="text"
                  value={monogramText}
                  onChange={(e) => setMonogramText(e.target.value)}
                />
                <Input
                  className="mb-2"
                  onChange={(e) => setMonogramFont(e.target.value)}
                  value={monogramFont}
                  type="select"
                  name="select"
                >
                  <option disabled selected value>
                    {' '}
                    -- select an option --{' '}
                  </option>
                  {Object.keys(fontsFor).map((val) => (
                    <option value={val}>{fontsFor[val]}</option>
                  ))}
                </Input>
                <Input
                  className="mb-2"
                  onChange={(e) => setMonogramColor(e.target.value)}
                  value={monogramColor}
                  type="select"
                  name="select"
                >
                  <option disabled selected value>
                    {' '}
                    -- select an option --{' '}
                  </option>
                  {Object.keys(colors).map((val) => (
                    <option style={{ color: colors[val] }} value={val}>
                      {val}
                    </option>
                  ))}
                </Input>
                {(monogramFont === 'block' ||
                  monogramFont === 'college' ||
                  monogramFont === 'heritage' ||
                  monogramFont === 'decorative') && (
                  <Input
                    className="mb-2"
                    onChange={(e) => setMonogramShadow(e.target.value)}
                    value={monogramShadow}
                    type="select"
                    name="select"
                  >
                    <option disabled selected value>
                      {' '}
                      -- select an option --{' '}
                    </option>
                    {Object.keys(newColors).map((val) => (
                      <option style={{ color: newColors[val] }} value={val}>
                        {val}
                      </option>
                    ))}
                  </Input>
                )}
                <Input
                  className="mb-2"
                  onChange={(e) => setMonogramLocation(e.target.value)}
                  value={monogramLocation}
                  type="select"
                  name="select"
                >
                  <option disabled selected value>
                    {' '}
                    -- select an option --{' '}
                  </option>
                  {selectedVariant?.sku?.includes('NBK')
                    ? Object.keys(Notebooklocations).map((val) => (
                        <option value={Notebooklocations[val]}>
                          {Notebooklocations[val]}
                        </option>
                      ))
                    : selectedVariant?.sku?.includes('TEC') ||
                      selectedVariant?.sku?.includes('CME')
                    ? Object.keys(TecLocations).map((val) => (
                        <option value={TecLocations[val]}>
                          {TecLocations[val]}
                        </option>
                      ))
                    : selectedVariant?.sku?.includes('TAG')
                    ? Object.keys(TagLocations).map((val) => (
                        <option value={TagLocations[val]}>
                          {TagLocations[val]}
                        </option>
                      ))
                    : Object.keys(locations).map((val) => (
                        <option value={locations[val]}>{locations[val]}</option>
                      ))}{' '}
                </Input>
              </div>
            )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={updateLineItem}>
            {' '}
            Add{' '}
          </Button>
        </ModalFooter>
      </div>
    </div>
  );
};

const FlaggedModal = ({ togglemodal, refresh, orderID }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFlagType, setSelectedFlagType] = useState();

  useEffect(() => {
    setLoading(true);
    superagent
      .get(`${backendUrl}/orders/getFlagTypes`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log('response.body', response.body);
          setOptions(response.body);
          setSelectedFlagType(response.body.data._id);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleFlagLineItemChanged = (e) => {
    const option = e.target.value;
    setSelectedFlagType(option);
  };

  const updateLineItem = () => {
    let newLineItem = {
      orderID: orderID,
      flagTypeId:
        selectedFlagType !== undefined ? selectedFlagType : options[0]?._id,
    };

    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/${orderID}/setFlagged`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if (loading === true) {
    return (
      <div>
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Choose Flag Type</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>Choose Flag Type</ModalHeader>
        <ModalBody>
          {/* {JSON.stringify(options)} */}
          <Input
            onChange={(e) => handleFlagLineItemChanged(e)}
            value={selectedFlagType}
            type="select"
            name="select"
            id="exampleSelect"
          >
            {options.map((option) => (
              <option
                value={
                  option?._id === undefined ? options[0]?._id : option?._id
                }
              >
                {option?.name}
              </option>
            ))}
          </Input>
          <br />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={updateLineItem}>
            {' '}
            Add{' '}
          </Button>
        </ModalFooter>
      </div>
    </div>
  );
};

const newColorsBags = {
  Coral: '#FDC0B8',
  'Dusty-Pink': '#F5DFE2',
  Fuschia: '#DF3C7F',
  Mint: '#D4EECC',
  'Sky-Blue': '#BBE0EE',
  'Aqua-Blue': '#47BCEE',
  'French-Navy': '#182D6D',
  Red: '#C63531',
  Plum: '#AC3856',
  Purple: '#64258E',
  Lavender: '#957EA3',
  'Forest-Green': '#1C4125',
  Green: '#6A9A52',
  Yellow: '#E8BE47',
  Orange: '#E96737',
  White: '#ffffff',
  Grey: '#97999D',
  Black: '#000',
};

export const newColors = {
  Lavender: '#90759B',
  Lilac: '#DCC5DF',
  'Ballet-Pink': '#F1C9DE',
  'Rose-Pink': '#DC75A4',
  Grenaine: '#9E5360',
  'Blush-Pink': '#E77472',
  Magenta: '#B12D52',
  Maroon: '#731A29',
  Red: '#C92327',
  'Burnt-Orange': '#DC5527',
  Mango: '#EF7E2E',
  Rust: '#9B4827',
  Toffee: '#705037',
  Taupe: '#D3A289',
  'Butter-Yellow': '#FBF3BE',
  Yellow: '#E8B629',
  Lime: '#C6B937',
  Green: '#4A813C',
  'Forest-Green': '#034123',
  Mint: '#78AB93',
  'Ice-Blue': '#BEE3E9',
  Denim: '#75A6B3',
  'Ocean-Blue': '#3E8EA1',
  Blue: '#1466B2',
  Grey: '#95979E',
  'Slate-Grey': '#63727E',
  Black: '#000000',
  White: '#FFFFFF',
};

export const fonts = {
  heritage: 'Heritage',
  'bold-new': 'Bold (new)',
  bubble: 'Bubble',
  'retro-serif': 'Retro Serif',
  graphic: 'Graphic',
  'sans-serif': 'Sans Serif',
  block: 'Block',
  signature: 'Signature',
  retro: 'Retro',
  serif: 'Serif',
  decorative: 'Decorative',

  painted: 'Painted',
  college: 'College',
  script: 'Script',
  casual: 'Casual',
  wide: 'Wide',
  bold: 'Block Serif',
  cursive: 'Modern Script',
  modern: 'Modern Serif',
  'zh-ser': 'Chinese Serif',
  'zh-san': 'Chinese Sans Serif',
  'zh-block': 'Chinese Block',
  'zh-script': 'Chinese Script',
  'zh-casual': 'Chinese Casual',
};

export const fontsBags = {
  'all-sans': 'Carry All Sans Serif',
  'all-serif': 'Carry All Serif',
};

export const embosFonts = {
  'sans-serif-embos': 'Sans Serif Emboss',
  'serif-embos': 'Serif Emboss',
};

export const embosColours = {
  Gold: '#FFD700',
  Silver: '#c0c0c0',
  Blind: '#212a2c',
};

export const locations = {
  Top: 'Top',
  Side: 'Side',
  Back: 'Back',
};

export const TecLocations = {
  Left: 'Left',
  Right: 'Right',
};

export const TagLocations = {
  Horizontal: 'Horizontal',
  Vertical: 'Vertical',
};

export const Notebooklocations = {
  'center-landscape': 'center-landscape',
  'center-portrait': 'center-portrait',
  'bottom-left': 'bottom-left',
};

export default OrderPage;
