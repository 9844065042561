export const navBarMenu = {
  'supply-chain': [
    {
      name: 'Overview',
      url: '/po-overview',
      lookup: 'overview',
    },
    {
      name: 'Containers',
      url: '/containers',
      lookup: 'container',
    },
    {
      name: 'Releases',
      url: '/releases',
      lookup: 'releases',
    },
    {
      name: 'Purchase Orders',
      url: '/purchase-order',
      lookup: 'purchase-order',
    },
    {
      name: 'Factories',
      url: '/factories',
      lookup: 'factories',
    },
  ],
  'notifications' :[
    {
      name: 'Inbox',
      url: '/notifications/inbox',
      lookup: 'notifications/inbox',
    },
    {
      name: 'Read',
      url: '/notifications/read',
      lookup: 'notifications/read',
    }
    
  ]
};
